import { MAX_CHALLENGES_EVER } from '../constants/settings'
import { solution } from './words'

const gameStateKey = 'gameState'
const highContrastKey = 'highContrast'
const matoranFontKey = 'matoranFont'

type StoredGameState = {
  guesses: string[]
  solution: string
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {
  localStorage.setItem(gameStateKey, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = () => {
  const state = localStorage.getItem(gameStateKey)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

const gameStatKey = 'gameStats'

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey)
  const r = stats ? (JSON.parse(stats) as GameStats) : null
  if (r) {
    // Fix existing data for max length.
    while (r.winDistribution.length < MAX_CHALLENGES_EVER) {
      r.winDistribution.push(0)
    }
    while (r.winDistribution.length > MAX_CHALLENGES_EVER) {
      r.winDistribution.pop()
    }
    for (let i = 0; i < r.winDistribution.length; i++) {
      r.winDistribution[i] = r.winDistribution[i] || 0
    }

    // If they already have correct solution for the day, ensure one win.
    const gameState = loadGameStateFromLocalStorage()
    if (gameState && gameState.solution === solution) {
      const index = gameState.guesses.indexOf(solution)
      if (index > -1) {
        r.winDistribution[index] = r.winDistribution[index] || 1
        saveStatsToLocalStorage(r)
      }
    }
  }
  return r
}

export const setStoredIsHighContrastMode = (isHighContrast: boolean) => {
  if (isHighContrast) {
    localStorage.setItem(highContrastKey, '1')
  } else {
    localStorage.removeItem(highContrastKey)
  }
}

export const getStoredIsHighContrastMode = () => {
  const highContrast = localStorage.getItem(highContrastKey)
  return highContrast === '1'
}

export const setStoredIsMatoranFontMode = (isMatoranFont: boolean) => {
  if (isMatoranFont) {
    localStorage.setItem(matoranFontKey, '1')
  } else {
    localStorage.removeItem(matoranFontKey)
  }
}

export const getStoredIsMatoranFontMode = () => {
  const matoranFont = localStorage.getItem(matoranFontKey)
  return matoranFont === '1'
}
