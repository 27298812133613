import { solution } from '../lib/words'
import { WORDS } from './wordlist'

export const WORD_LENGTH_TO_GUESSES = 1
export const HOME_NAME = process.env.REACT_APP_HOME_NAME!
export const HOME_URL = process.env.REACT_APP_HOME_URL!
export const SHARE_URL = process.env.REACT_APP_SHARE_URL!
export const MAX_WORD_LENGTH = solution.length
export const MAX_WORDS_LENGTH = WORDS.reduce((a, b) =>
  a.length > b.length ? a : b
).length
export const MAX_CHALLENGES = MAX_WORD_LENGTH + WORD_LENGTH_TO_GUESSES
export const MAX_CHALLENGES_EVER = MAX_WORDS_LENGTH + WORD_LENGTH_TO_GUESSES
export const ALERT_TIME_MS = 2000
export const REVEAL_TIME_MS = 350
export const GAME_LOST_INFO_DELAY = (MAX_WORD_LENGTH + 1) * REVEAL_TIME_MS
export const WELCOME_INFO_MODAL_MS = 350
