export const WORDS = [
  'onewa',
  'skakdi',
  'phantoka',
  'vahki',
  'matoro',
  'vizuna',
  'exo-toa',
  'zyglak',
  'mahiki',
  'nixie',
  'droton',
  'miserix',
  'kirbraz',
  'akilini',
  'dikapi',
  'mazeka',
  'nui-jaga',
  'skrall',
  'chiara',
  'trinuma',
  'kraatu',
  'harakeke',
  'su-kal',
  'zaria',
  'kiina',
  'le-wahi',
  'sanso',
  'kralhi',
  'kolhii',
  'gorast',
  'raanu',
  'jaller',
  'guurahk',
  'kraahu',
  'volitak',
  'atero',
  'matoran',
  'lhikan',
  'kane-ra',
  'buzkayo',
  'crast',
  'arktinen',
  'rockoh',
  'hewkii',
  'tridax',
  'pewku',
  'valmai',
  'keras',
  'gukko',
  'panrahk',
  'mangaia',
  'kalama',
  'botar',
  'baranus',
  'radiak',
  'zakaz',
  'rokreng',
  'gavla',
  'heremus',
  'kamen',
  'ussal',
  'nuhvok',
  'moto-hub',
  'aiyetoro',
  'golyo',
  'mamuk',
  'felnas',
  'nireta',
  'fenrakk',
  'maxilos',
  'widget',
  'narmoto',
  'thornax',
  'pouks',
  'ga-kini',
  'kyrehx',
  'sarda',
  'idris',
  'vezon',
  'mantax',
  'hordika',
  'tarduk',
  'ga-metru',
  'tajun',
  'faxon',
  'exsidian',
  'za-kal',
  'stelt',
  'fikou',
  'mana-ko',
  'kraata',
  'vorox',
  'muaka',
  'gahlok',
  'lehvak',
  'vohtarak',
  'krekka',
  'skopio',
  'ta-suva',
  'mukau',
  'mistika',
  'spikit',
  'akamu',
  'kakama',
  'pehkui',
  'takadox',
  'jaatikko',
  'umarak',
  'ketar',
  'nui-rama',
  'umbra',
  'nuhrii',
  'stronius',
  'avohkii',
  'keelerak',
  'karzahni',
  'lariska',
  'tanma',
  'wairuha',
  'po-koro',
  'shasa',
  'tahtorak',
  'kadin',
  'lesovikk',
  'kraawa',
  'ahkmou',
  'kodan',
  'cendox',
  'shelek',
  'talvi',
  'krakua',
  'tuyet',
  'xa-kal',
  'zatth',
  'oohnorak',
  'dekar',
  'onu-kini',
  'antroz',
  'melea',
  'turahk',
  'macku',
  'gaardus',
  'velika',
  'skirmix',
  'protodax',
  'ko-kini',
  'takua',
  'makuta',
  'pokawi',
  'akamai',
  'ngalawa',
  'kopeke',
  'kaita',
  'kalmah',
  'de-koro',
  'gaaki',
  'tarix',
  'piatra',
  'sanok',
  'melum',
  'kestora',
  'zamor',
  'boggarak',
  'amaja',
  'nidhiki',
  'kabrua',
  'turaga',
  'kiril',
  'okotan',
  'ga-suva',
  'hahnah',
  'teridax',
  'garan',
  'okoth',
  'hydraxon',
  'nynrah',
  'graalok',
  'roodaka',
  'makoki',
  'mohtrek',
  'agarak',
  'nokama',
  'kapura',
  'vorahk',
  'kahgarak',
  'berix',
  'onu-suva',
  'roxtus',
  'tehutti',
  'takea',
  'tahnok',
  'pekka',
  'yo-kal',
  'solek',
  'makani',
  'kaxium',
  'rahaga',
  'kojol',
  'fireroot',
  'jagiri',
  'acidfly',
  'balta',
  'ko-wahi',
  'tohunga',
  'zadakh',
  'kollorak',
  'makika',
  'krana',
  'ta-koro',
  'kinloka',
  'braca',
  'kanohi',
  'damek',
  'ussanui',
  'thulox',
  'roporak',
  'boreas',
  'ta-metru',
  'visorak',
  'takara',
  'hakann',
  'atakus',
  'sidorak',
  'axalara',
  'pohatu',
  'telluris',
  'zemya',
  'metus',
  'terak',
  'ta-wahi',
  'epolim',
  'burnak',
  'vorzakh',
  'daikau',
  'rorzakh',
  'bo-kal',
  'artakha',
  'malum',
  'gahdok',
  'uganu',
  'kongu',
  'norik',
  'krahka',
  'le-metru',
  'kopaka',
  'ussalry',
  'olisi',
  'takanuva',
  'destral',
  'krika',
  'branar',
  'kualsi',
  'kurahk',
  'vatuka',
  'kirbold',
  'lerahk',
  'longfang',
  'izotor',
  'toudo',
  'le-koro',
  'mutran',
  'iruini',
  'pakastaa',
  'mamru',
  'kokkan',
  'etoku',
  'tamaru',
  'waikiru',
  'bohrok',
  'devourer',
  'likus',
  'strakk',
  'calix',
  'zaktan',
  'agori',
  'po-metru',
  'manas',
  'amphibax',
  'hagah',
  'kavinika',
  'helryx',
  'defilak',
  'gadjati',
  'dosne',
  'odina',
  'niazesk',
  'ga-koro',
  'spiriah',
  'nilkuu',
  'ekimu',
  'marka',
  'carapar',
  'keetongu',
  'ko-koro',
  'kaukau',
  'morak',
  'hapaka',
  'surel',
  'matau',
  'korusca',
  'pridak',
  'vamprah',
  'suukorak',
  'mahri',
  'le-kini',
  'perditus',
  'bahrag',
  'nuurakh',
  'tesara',
  'harvali',
  'vakama',
  'lohrak',
  'owaki',
  'vu-kal',
  'axonn',
  'po-kini',
  'tobduk',
  'ehlek',
  'bionicle',
  'ehrye',
  'komau',
  'kerato',
  'boxor',
  'bingzak',
  'kualus',
  'vulcanus',
  'bordakh',
  'metru',
  'kini-nui',
  'nivawk',
  'korgot',
  'kraahkan',
  'kulta',
  'orkahm',
  'codrex',
  'nobua',
  'voporak',
  'vezok',
  'le-suva',
  'udapo',
  'rhotuka',
  'baterra',
  'keahi',
  'icarax',
  'vastus',
  'maglya',
  'kylma',
  'jutlin',
  'triglax',
  'onu-koro',
  'reidak',
  'angonce',
  'hikaki',
  'hydruka',
  'amaya',
  'dormus',
  'ga-wahi',
  'cordak',
  'kirop',
  'brakas',
  'kanoka',
  'tuuli',
  'mavrah',
  'garai',
  'matatu',
  'rahkshi',
  'sahmad',
  'kikanalo',
  'varian',
  'ta-kini',
  'photok',
  'kohrak',
  'inika',
  'bitil',
  'click',
  'annona',
  'onepu',
  'tarakava',
  'ca-kal',
  'kailani',
  'jetrax',
  'kranua',
  'nuparu',
  'ba-koro',
  'pahrak',
  'okoto',
  'zivon',
  'arthron',
  'kivoda',
  'x-glider',
  'orkan',
  'mangai',
  'spinax',
  'manutri',
  'vican',
  'pelagia',
  'kardas',
  'barraki',
  'vhisola',
  'brutaka',
  'kantai',
  'axato',
  'po-suva',
  'taipu',
  'piraka',
  'vultraz',
  'ignika',
  'suletu',
  'flammik',
  'akmuo',
  'akaku',
  'klakk',
  'ignalu',
  'hahli',
  'avohkah',
  'ghekula',
  'vortixx',
  'dezalk',
  'gadunka',
  'whenua',
  'ko-metru',
  'gafna',
  'epena',
  'daxia',
  'akida',
  'irnakk',
  'pakari',
  'sentrakh',
  'jovan',
  'johmak',
  'onu-wahi',
  'tryna',
  'olmak',
  'airweed',
  'bumonda',
  'reysa',
  'tri-claw',
  'nikila',
  'ackar',
  'aodhan',
  'jerbraz',
  'certavus',
  'po-wahi',
  'cahdok',
  'gresh',
  'artidax',
  'piruk',
  'nocturn',
  'iconox',
  'ja-kal',
  'midak',
  'keerakh',
  'azibo',
  'ko-suva',
  'vohon',
  'marendar',
  'ranama',
  'bomonga',
  'kuma-nui',
  'scarabax',
  'nektann',
  'taiki',
  'chirox',
  //////////////////////////////////////////////////////////////////////////////
  'comet',
  'pekka',
  'thulox',
  'morak',
  'gadjati',
  'kane-ra',
  'pakari',
  'jaller',
  'matoran',
  'kanoka',
  'phantoka',
  'mamru',
  'nuhrii',
  'reysa',
  'zamor',
  'akamai',
  'kamen',
  'marka',
  'metru',
  'fireroot',
  'jutlin',
  'burnak',
  'hordika',
  'epena',
  'kohrak',
  'bohrok',
  'guardian',
  'strakk',
  'udapo',
  'agori',
  'vulcanus',
  'nynrah',
  'vezok',
  'kanohi',
  'nokama',
  'macku',
  'arthron',
  'malum',
  'ko-metru',
  'mamoru',
  'boreas',
  'makoki',
  'odina',
  'keerakh',
  'berix',
  'orkahm',
  'kini-nui',
  'gaaki',
  'po-suva',
  'takadox',
  'boggarak',
  'akamu',
  'gavla',
  'graalok',
  'lerahk',
  'dekar',
  'zemya',
  'tri-claw',
  'ussanui',
  'zaktan',
  'kraahu',
  'pewku',
  'klakk',
  'melea',
  'de-koro',
  'krahka',
  'airweed',
  'takanuva',
  'mangaia',
  'kiril',
  'ta-kini',
  'kivoda',
  'tunneler',
  'arktinen',
  'balta',
  'ravager',
  'keelerak',
  'kadin',
  'gukko',
  'takua',
  'exo-toa',
  'hydraxon',
  'chiara',
  'umarak',
  'piraka',
  'ehrye',
  'devourer',
  'piruk',
  'ussal',
  'pridak',
  'dweller',
  'nobua',
  'agarak',
  'silence',
  'pokawi',
  'jovan',
  'nivawk',
  'raanu',
  'yo-kal',
  'daxia',
  'mahiki',
  'angonce',
  'taipu',
  'tehutti',
  'jagiri',
  'minion',
  'okoth',
  'zadakh',
  'hagah',
  'pelagia',
  'manutri',
  'aiyetoro',
  'turahk',
  'nui-rama',
  'ignika',
  'vican',
  'stelt',
  'ketar',
  'whenua',
  'gahdok',
  'heremus',
  'avohkii',
  'za-kal',
  'keras',
  'vastus',
  'exsidian',
  'olisi',
  'hahnah',
  'gatherer',
  'bomonga',
  'lehvak',
  'nektann',
  'likus',
  'ranama',
  'tyrant',
  'kranua',
  'vhisola',
  'aodhan',
  'tarix',
  'matau',
  'mazeka',
  'jetrax',
  'fikou',
  'vizuna',
  'harvali',
  'droton',
  'panrahk',
  'sidorak',
  'kolhii',
  'velika',
  'skyboard',
  'nui-jaga',
  'takara',
  'jaatikko',
  'nilkuu',
  'sanctum',
  'rokreng',
  'krekka',
  'atakus',
  'ga-suva',
  'iruini',
  'x-glider',
  'kahgarak',
  'makuta',
  'maglya',
  'po-kini',
  'kestora',
  'norik',
  'kraatu',
  'vorzakh',
  'etoku',
  'spiriah',
  'zivon',
  'ca-kal',
  'makani',
  'telluris',
  'kualsi',
  'kraata',
  'uniter',
  'korgot',
  'protodax',
  'onu-wahi',
  'varian',
  'ekimu',
  'amaya',
  'sanso',
  'ko-kini',
  'le-kini',
  'xa-kal',
  'lohrak',
  'flammik',
  'rhotuka',
  'kalama',
  'rahkshi',
  'tridax',
  'karzahni',
  'sentrakh',
  'savage',
  'kirbold',
  'tesara',
  'nuurakh',
  'bitil',
  'skopio',
  'hydruka',
  'widget',
  'sanok',
  'scarabax',
  'ta-wahi',
  'thornax',
  'po-wahi',
  'rockoh',
  'turaga',
  'kodan',
  'damek',
  'matoro',
  'tamaru',
  'mohtrek',
  'ancient',
  'kopaka',
  'vamprah',
  'lesovikk',
  'vatuka',
  'le-koro',
  'toudo',
  'dosne',
  'vortixx',
  'akilini',
  'ga-koro',
  'lhikan',
  'onu-kini',
  'bionicle',
  'boxor',
  'spinner',
  'keahi',
  'talvi',
  'kaita',
  'mantax',
  'kongu',
  'takea',
  'olmak',
  'coliseum',
  'mistika',
  'pohatu',
  'kyrehx',
  'vultraz',
  'mamuk',
  'ta-metru',
  'manas',
  'ko-wahi',
  'kaukau',
  'troller',
  'ta-koro',
  'tuuli',
  'tahtorak',
  'buzkayo',
  'zyglak',
  'kailani',
  'moto-hub',
  'kualus',
  'garai',
  'reidak',
  'skakdi',
  'mutran',
  'idris',
  'kirbraz',
  'kollorak',
  'suletu',
  'kikanalo',
  'icarax',
  'shelek',
  'vanisher',
  'bingzak',
  'hapaka',
  'kinloka',
  'bumonda',
  'primal',
  'uganu',
  'brutaka',
  'tohunga',
  'mimic',
  'roodaka',
  'kraahkan',
  'baterra',
  'kerato',
  'archives',
  'longfang',
  'vakama',
  'ackar',
  'okoto',
  'le-metru',
  'kiina',
  'avohkah',
  'visorak',
  'mavrah',
  'tryna',
  'crast',
  'sahmad',
  'midak',
  'hahli',
  'hikaki',
  'ahkmou',
  'jerbraz',
  'su-kal',
  'onu-suva',
  'voporak',
  'taiki',
  'spinax',
  'akmuo',
  'amphibax',
  'onepu',
  'roporak',
  'golyo',
  'azibo',
  'axalara',
  'gaardus',
  'ga-wahi',
  'tobduk',
  'conjurer',
  'vorahk',
  'piatra',
  'ga-kini',
  'skrall',
  'oohnorak',
  'melum',
  'tarduk',
  'skirmix',
  'vu-kal',
  'shasa',
  'kopeke',
  'vohtarak',
  'gadunka',
  'umbra',
  'tracker',
  'kokkan',
  'devourer',
  'stronius',
  'bo-kal',
  'gafna',
  'axonn',
  'le-suva',
  'akida',
  'kalmah',
  'ussalry',
  'zaria',
  'nuparu',
  'garan',
  'epolim',
  'kojol',
  'baranus',
  'kurahk',
  'metus',
  'po-koro',
  'trinuma',
  'kakama',
  'pouks',
  'mana-ko',
  'fenrakk',
  'dormus',
  'iconox',
  'kavinika',
  'mahri',
  'kantai',
  'defilak',
  'nikila',
  'charger',
  'po-metru',
  'orkan',
  'tarakava',
  'makika',
  'nireta',
  'spikit',
  'teridax',
  'rorzakh',
  'artakha',
  'ko-suva',
  'botar',
  'maxilos',
  'tajun',
  'ko-koro',
  'irnakk',
  'vohon',
  'photok',
  'faxon',
  'ghekula',
  'mangai',
  'tanma',
  'muaka',
  'wairuha',
  'kaxium',
  'phantom',
  'perditus',
  'braca',
  'rahaga',
  'inika',
  'gorast',
  'brakas',
  'cordak',
  'tahnok',
  'akaku',
  'chirox',
  'nuhvok',
  'vezon',
  'owaki',
  'antroz',
  'izotor',
  'kulta',
  'axato',
  'codrex',
  'johmak',
  'vorox',
  'pehkui',
  'acidfly',
  'korusca',
  'bahrag',
  'vahki',
  'nocturn',
  'gahlok',
  'nidhiki',
  'waikiru',
  'keystone',
  'pakastaa',
  'matatu',
  'ehlek',
  'lurker',
  'zakaz',
  'annona',
  'keetongu',
  'atero',
  'komau',
  'kirop',
  'kraawa',
  'daikau',
  'krakua',
  'lariska',
  'hewkii',
  'harakeke',
  'barraki',
  'volitak',
  'carapar',
  'marendar',
  'kuma-nui',
  'terak',
  'click',
  'ga-metru',
  'surel',
  'kylma',
  'roxtus',
  'branar',
  'mukau',
  'kardas',
  'ta-suva',
  'kabrua',
  'onu-koro',
  'radiak',
  'hakann',
  'calix',
  'destral',
  'ignalu',
  'miserix',
  'felnas',
  'valmai',
  'solek',
  'narmoto',
  'zatth',
  'dikapi',
  'cahdok',
  'guurahk',
  'ngalawa',
  'motara',
  'bordakh',
  'onewa',
  'krana',
  'ba-koro',
  'gresh',
  'brander',
  'sarda',
  'certavus',
  'pahrak',
  'okotan',
  'cendox',
  'le-wahi',
  'dezalk',
  'seeker',
  'helryx',
  'ja-kal',
  'niazesk',
  'nixie',
  'tuyet',
  'triglax',
  'kralhi',
  'artidax',
  'krika',
  'kapura',
  'amaja',
  'suukorak',
  //////////////////////////////////////////////////////////////////////////////
  'ko-koro',
  'kopaka',
  'artakha',
  'kardas',
  'po-koro',
  'cordak',
  'charger',
  'brutaka',
  'ko-suva',
  'vakama',
  'ba-koro',
  'axato',
  'tohunga',
  'karzahni',
  'kiril',
  'kylma',
  'moto-hub',
  'valmai',
  'velika',
  'vorzakh',
  'ranama',
  'ta-metru',
  'nuhvok',
  'guurahk',
  'ga-wahi',
  'skopio',
  'vohon',
  'agori',
  'korusca',
  'motara',
  'ghekula',
  'akida',
  'baranus',
  'kodan',
  'mazeka',
  'matau',
  'taiki',
  'vhisola',
  'ko-metru',
  'akamai',
  'reysa',
  'mahri',
  'harvali',
  'epena',
  'hahnah',
  'aiyetoro',
  'nireta',
  'pokawi',
  'droton',
  'kerato',
  'maglya',
  'bingzak',
  'kaxium',
  'mamoru',
  'surel',
  'skyboard',
  'metus',
  'kailani',
  'skrall',
  'odina',
  'vorox',
  'voporak',
  'hagah',
  'nikila',
  'burnak',
  'lariska',
  'garan',
  'piraka',
  'talvi',
  'morak',
  'kurahk',
  'tahnok',
  'minion',
  'akmuo',
  'antroz',
  'helryx',
  'scarabax',
  'fikou',
  'gresh',
  'kokkan',
  'atakus',
  'okotan',
  'vohtarak',
  'zakaz',
  'midak',
  'visorak',
  'reidak',
  'azibo',
  'po-kini',
  'savage',
  'ekimu',
  'dikapi',
  'keelerak',
  'le-wahi',
  'jerbraz',
  'kivoda',
  'su-kal',
  'chiara',
  'teridax',
  'mamuk',
  'suukorak',
  'krekka',
  'olisi',
  'piatra',
  'ignalu',
  'archives',
  'oohnorak',
  'onepu',
  'kalama',
  'tridax',
  'iruini',
  'tunneler',
  'gadjati',
  'primal',
  'kolhii',
  'lerahk',
  'kanohi',
  'mahiki',
  'avohkii',
  'tesara',
  'vortixx',
  'hikaki',
  'mangai',
  'maxilos',
  'gorast',
  'bionicle',
  'zaktan',
  'kapura',
  'roxtus',
  'trinuma',
  'kaukau',
  'braca',
  'mistika',
  'cendox',
  'ancient',
  'pakari',
  'pekka',
  'mana-ko',
  'gaardus',
  'kopeke',
  'nui-jaga',
  'dezalk',
  'makuta',
  'sidorak',
  'spinax',
  'klakk',
  'baterra',
  'damek',
  'takea',
  'tarix',
  'silence',
  'amaja',
  'hydruka',
  'owaki',
  'akamu',
  'skakdi',
  'hahli',
  'certavus',
  'stronius',
  'varian',
  'izotor',
  'tajun',
  'melum',
  'mutran',
  'zatth',
  'zaria',
  'manas',
  'tehutti',
  'annona',
  'panrahk',
  'bordakh',
  'gadunka',
  'gafna',
  'orkahm',
  'volitak',
  'tyrant',
  'za-kal',
  'vu-kal',
  'kadin',
  'dormus',
  'kyrehx',
  'bitil',
  'amphibax',
  'makoki',
  'pouks',
  'keahi',
  'matatu',
  'kojol',
  'x-glider',
  'golyo',
  'macku',
  'le-suva',
  'fenrakk',
  'kirbraz',
  'gaaki',
  'brakas',
  'piruk',
  'destral',
  'zemya',
  'zamor',
  'ketar',
  'acidfly',
  'hydraxon',
  'jutlin',
  'defilak',
  'idris',
  'buzkayo',
  'udapo',
  'irnakk',
  'ackar',
  'kraahu',
  'bohrok',
  'tarduk',
  'comet',
  'terak',
  'lesovikk',
  'heremus',
  'phantom',
  'makika',
  'airweed',
  'kohrak',
  'ga-koro',
  'nynrah',
  'sarda',
  'uniter',
  'lurker',
  'pahrak',
  'hapaka',
  'barraki',
  'whenua',
  'le-koro',
  'carapar',
  'ca-kal',
  'suletu',
  'codrex',
  'lehvak',
  'kulta',
  'po-wahi',
  'nuparu',
  'brander',
  'nivawk',
  'wairuha',
  'ta-wahi',
  'axalara',
  'takua',
  'vorahk',
  'vulcanus',
  'boxor',
  'faxon',
  'ja-kal',
  'ehrye',
  'mangaia',
  'tri-claw',
  'icarax',
  'tarakava',
  'metru',
  'ignika',
  'boreas',
  'kalmah',
  'ta-suva',
  'ta-kini',
  'axonn',
  'po-metru',
  'tahtorak',
  'roporak',
  'ko-kini',
  'okoto',
  'nidhiki',
  'akilini',
  'artidax',
  'zivon',
  'coliseum',
  'boggarak',
  'kraata',
  'lohrak',
  'marendar',
  'kollorak',
  'tuuli',
  'kaita',
  'exsidian',
  'nilkuu',
  'likus',
  'widget',
  'protodax',
  'aodhan',
  'vahki',
  'guardian',
  'agarak',
  'orkan',
  'rhotuka',
  'kestora',
  'bahrag',
  'jetrax',
  'kahgarak',
  'sanok',
  'atero',
  'nobua',
  'nocturn',
  'gukko',
  'ngalawa',
  'exo-toa',
  'vatuka',
  'balta',
  'ahkmou',
  'miserix',
  'akaku',
  'calix',
  'vican',
  'takadox',
  'pehkui',
  'solek',
  'mamru',
  'dosne',
  'jaatikko',
  'gatherer',
  'rorzakh',
  'rokreng',
  'vezok',
  'pewku',
  'tuyet',
  'ga-metru',
  'photok',
  'mavrah',
  'garai',
  'vastus',
  'onu-wahi',
  'ussanui',
  'marka',
  'pakastaa',
  'krahka',
  'vultraz',
  'keerakh',
  'kanoka',
  'radiak',
  'felnas',
  'crast',
  'branar',
  'vamprah',
  'stelt',
  'kraawa',
  'phantoka',
  'kantai',
  'norik',
  'keetongu',
  'uganu',
  'vizuna',
  'kamen',
  'kralhi',
  'hakann',
  'devourer',
  'mukau',
  'skirmix',
  'jovan',
  'arktinen',
  'le-kini',
  'mohtrek',
  'berix',
  'iconox',
  'spikit',
  'krana',
  'pridak',
  'spiriah',
  'kranua',
  'tracker',
  'zyglak',
  'ta-koro',
  'kongu',
  'waikiru',
  'avohkah',
  'amaya',
  'kavinika',
  'korgot',
  'kualus',
  'raanu',
  'rockoh',
  'taipu',
  'makani',
  'hewkii',
  'bomonga',
  'etoku',
  'le-metru',
  'click',
  'kiina',
  'yo-kal',
  'rahaga',
  'turahk',
  'pohatu',
  'harakeke',
  'zadakh',
  'tobduk',
  'po-suva',
  'cahdok',
  'tamaru',
  'lhikan',
  'rahkshi',
  'kualsi',
  'thulox',
  'thornax',
  'manutri',
  'perditus',
  'shasa',
  'arthron',
  'tryna',
  'dweller',
  'strakk',
  'conjurer',
  'ga-kini',
  'keras',
  'dekar',
  'matoran',
  'narmoto',
  'ga-suva',
  'pelagia',
  'nektann',
  'ussalry',
  'hordika',
  'turaga',
  'kini-nui',
  'olmak',
  'bumonda',
  'ehlek',
  'ussal',
  'fireroot',
  'triglax',
  'seeker',
  'sanctum',
  'ravager',
  'komau',
  'shelek',
  'jagiri',
  'nui-rama',
  'johmak',
  'longfang',
  'takanuva',
  'sentrakh',
  'okoth',
  'tanma',
  'de-koro',
  'kirop',
  'inika',
  'kikanalo',
  'botar',
  'niazesk',
  'kakama',
  'muaka',
  'kane-ra',
  'jaller',
  'toudo',
  'matoro',
  'kraahkan',
  'devourer',
  'kinloka',
  'onu-suva',
  'vanisher',
  'sanso',
  'umbra',
  'daxia',
  'kuma-nui',
  'telluris',
  'umarak',
  'takara',
  'ko-wahi',
  'kraatu',
  'gahdok',
  'daikau',
  'krika',
  'malum',
  'gavla',
  'angonce',
  'mantax',
  'nokama',
  'nuurakh',
  'sahmad',
  'keystone',
  'xa-kal',
  'chirox',
  'krakua',
  'bo-kal',
  'roodaka',
  'onu-kini',
  'kabrua',
  'onu-koro',
  'graalok',
  'kirbold',
  'onewa',
  'troller',
  'flammik',
  'mimic',
  'vezon',
  'melea',
  'nixie',
  'nuhrii',
  'epolim',
  'spinner',
  'gahlok',
  //////////////////////////////////////////////////////////////////////////////
  'kulta',
  'pokawi',
  'dosne',
  'kardas',
  'manutri',
  'ko-kini',
  'suletu',
  'le-wahi',
  'hahli',
  'annona',
  'skirmix',
  'epena',
  'axato',
  'ussal',
  'makuta',
  'vakama',
  'sentrakh',
  'uganu',
  'kalama',
  'vorzakh',
  'tahtorak',
  'seeker',
  'keelerak',
  'vanisher',
  'morak',
  'ba-koro',
  'vastus',
  'onepu',
  'korgot',
  'kuma-nui',
  'certavus',
  'radiak',
  'kaukau',
  'mistika',
  'vorox',
  'etoku',
  'hikaki',
  'jerbraz',
  'widget',
  'po-suva',
  'mamuk',
  'ussanui',
  'matoran',
  'klakk',
  'ta-kini',
  'vahki',
  'niazesk',
  'nuhvok',
  'daxia',
  'ta-suva',
  'onewa',
  'oohnorak',
  'onu-koro',
  'sanso',
  'faxon',
  'bohrok',
  'valmai',
  'x-glider',
  'mutran',
  'exo-toa',
  'krana',
  'ga-wahi',
  'barraki',
  'stelt',
  'archives',
  'pohatu',
  'onu-suva',
  'kanohi',
  'kinloka',
  'defilak',
  'cordak',
  'onu-kini',
  'hydraxon',
  'toudo',
  'amphibax',
  'kapura',
  'thornax',
  'iruini',
  'ko-koro',
  'skopio',
  'kopeke',
  'minion',
  'kraahu',
  'ngalawa',
  'voporak',
  'garai',
  'antroz',
  'lerahk',
  'guurahk',
  'krekka',
  'gaardus',
  'roodaka',
  'kiina',
  'bordakh',
  'savage',
  'mukau',
  'gavla',
  'dikapi',
  'whenua',
  'kikanalo',
  'le-metru',
  'macku',
  'marka',
  'hakann',
  'lariska',
  'kestora',
  'vohtarak',
  'baterra',
  'shelek',
  'nektann',
  'takua',
  'rahkshi',
  'skyboard',
  'za-kal',
  'strakk',
  'roxtus',
  'conjurer',
  'telluris',
  'mimic',
  'wairuha',
  'owaki',
  'gadunka',
  'de-koro',
  'gahlok',
  'umarak',
  'artidax',
  'nui-rama',
  'takanuva',
  'motara',
  'marendar',
  'gatherer',
  'buzkayo',
  'inika',
  'keras',
  'maxilos',
  'atakus',
  'skrall',
  'kailani',
  'arktinen',
  'brakas',
  'hahnah',
  'artakha',
  'botar',
  'ko-suva',
  'devourer',
  'kadin',
  'takara',
  'maglya',
  'lhikan',
  'cendox',
  'jovan',
  'narmoto',
  'vatuka',
  'malum',
  'mohtrek',
  'kaita',
  'nixie',
  'krakua',
  'burnak',
  'tryna',
  'tyrant',
  'damek',
  'golyo',
  'primal',
  'ga-suva',
  'bahrag',
  'nui-jaga',
  'spikit',
  'hapaka',
  'manas',
  'kongu',
  'tesara',
  'kodan',
  'vohon',
  'su-kal',
  'flammik',
  'boxor',
  'dekar',
  'tobduk',
  'nuhrii',
  'okotan',
  'ackar',
  'terak',
  'rockoh',
  'jutlin',
  'gorast',
  'acidfly',
  'zivon',
  'karzahni',
  'nidhiki',
  'tahnok',
  'onu-wahi',
  'kralhi',
  'krahka',
  'kylma',
  'amaja',
  'comet',
  'piraka',
  'shasa',
  'kokkan',
  'mangaia',
  'jaller',
  'tarakava',
  'matau',
  'pahrak',
  'akaku',
  'destral',
  'kerato',
  'akmuo',
  'kualsi',
  'vultraz',
  'keahi',
  'komau',
  'tajun',
  'kahgarak',
  'olisi',
  'gahdok',
  'ranama',
  'mangai',
  'ga-koro',
  'reysa',
  'teridax',
  'makoki',
  'braca',
  'mazeka',
  'tri-claw',
  'moto-hub',
  'zaktan',
  'kamen',
  'keerakh',
  'ja-kal',
  'silence',
  'akamu',
  'ravager',
  'bumonda',
  'takea',
  'ignalu',
  'click',
  'dweller',
  'kohrak',
  'nuparu',
  'bo-kal',
  'nilkuu',
  'umbra',
  'aodhan',
  'amaya',
  'vortixx',
  'zaria',
  'gafna',
  'kraawa',
  'gadjati',
  'kantai',
  'boreas',
  'krika',
  'balta',
  'nireta',
  'nikila',
  'ketar',
  'axalara',
  'kollorak',
  'chiara',
  'muaka',
  'keystone',
  'ghekula',
  'reidak',
  'carapar',
  'tohunga',
  'exsidian',
  'chirox',
  'yo-kal',
  'tracker',
  'zemya',
  'taipu',
  'sahmad',
  'scarabax',
  'atero',
  'ancient',
  'boggarak',
  'stronius',
  'helryx',
  'ignika',
  'coliseum',
  'okoto',
  'fikou',
  'varian',
  'tunneler',
  'mamoru',
  'matoro',
  'brander',
  'piatra',
  'avohkah',
  'nokama',
  'tuyet',
  'vizuna',
  'cahdok',
  'nobua',
  'suukorak',
  'epolim',
  'tamaru',
  'pakastaa',
  'ga-metru',
  'pridak',
  'trinuma',
  'brutaka',
  'sidorak',
  'sanok',
  'pewku',
  'kraatu',
  'felnas',
  'hydruka',
  'kirop',
  'icarax',
  'waikiru',
  'visorak',
  'agori',
  'hagah',
  'melea',
  'izotor',
  'lurker',
  'charger',
  'bomonga',
  'kolhii',
  'volitak',
  'po-wahi',
  'longfang',
  'uniter',
  'ga-kini',
  'vican',
  'phantom',
  'ehlek',
  'solek',
  'kranua',
  'idris',
  'axonn',
  'bionicle',
  'crast',
  'agarak',
  'dezalk',
  'kirbraz',
  'heremus',
  'korusca',
  'kraata',
  'photok',
  'avohkii',
  'pelagia',
  'vu-kal',
  'troller',
  'vezon',
  'berix',
  'triglax',
  'lehvak',
  'rorzakh',
  'nynrah',
  'jaatikko',
  'xa-kal',
  'kraahkan',
  'fireroot',
  'le-kini',
  'matatu',
  'po-kini',
  'aiyetoro',
  'devourer',
  'mamru',
  'zatth',
  'surel',
  'spinax',
  'makani',
  'lesovikk',
  'fenrakk',
  'tanma',
  'tarduk',
  'mahiki',
  'ussalry',
  'lohrak',
  'vorahk',
  'bitil',
  'kivoda',
  'spiriah',
  'baranus',
  'kakama',
  'calix',
  'roporak',
  'phantoka',
  'kurahk',
  'mavrah',
  'okoth',
  'gresh',
  'harvali',
  'droton',
  'akamai',
  'vezok',
  'zakaz',
  'skakdi',
  'perditus',
  'le-suva',
  'irnakk',
  'ta-metru',
  'taiki',
  'talvi',
  'likus',
  'thulox',
  'protodax',
  'ko-metru',
  'piruk',
  'kualus',
  'olmak',
  'zamor',
  'norik',
  'tridax',
  'po-metru',
  'mana-ko',
  'jetrax',
  'mahri',
  'sanctum',
  'pouks',
  'azibo',
  'ta-wahi',
  'zyglak',
  'metru',
  'ca-kal',
  'sarda',
  'bingzak',
  'makika',
  'ahkmou',
  'ehrye',
  'tuuli',
  'branar',
  'kane-ra',
  'angonce',
  'po-koro',
  'harakeke',
  'kopaka',
  'ta-koro',
  'vulcanus',
  'nivawk',
  'takadox',
  'graalok',
  'melum',
  'garan',
  'kyrehx',
  'kabrua',
  'nuurakh',
  'johmak',
  'kiril',
  'pehkui',
  'zadakh',
  'le-koro',
  'kirbold',
  'vhisola',
  'kavinika',
  'orkahm',
  'nocturn',
  'midak',
  'tehutti',
  'velika',
  'kaxium',
  'turaga',
  'rhotuka',
  'turahk',
  'orkan',
  'kojol',
  'hewkii',
  'codrex',
  'arthron',
  'airweed',
  'kalmah',
  'pekka',
  'udapo',
  'keetongu',
  'pakari',
  'odina',
  'tarix',
  'mantax',
  'kanoka',
  'gaaki',
  'vamprah',
  'dormus',
  'rokreng',
  'guardian',
  'gukko',
  'spinner',
  'ko-wahi',
  'rahaga',
  'kini-nui',
  'metus',
  'daikau',
  'hordika',
  'raanu',
  'akilini',
  'miserix',
  'ekimu',
  'panrahk',
  'jagiri',
  'akida',
  'iconox',
  //////////////////////////////////////////////////////////////////////////////
  'kaita',
  'cahdok',
  'onepu',
  'codrex',
  'mimic',
  'jutlin',
  'conjurer',
  'harakeke',
  'rhotuka',
  'charger',
  'matau',
  'devourer',
  'kraata',
  'bitil',
  'kailani',
  'nektann',
  'kanoka',
  'okotan',
  'kualus',
  'ranama',
  'zatth',
  'devourer',
  'kalama',
  'kraatu',
  'trinuma',
  'ravager',
  'nikila',
  'zyglak',
  'morak',
  'mangai',
  'zamor',
  'ahkmou',
  'sanso',
  'dekar',
  'certavus',
  'akaku',
  'etoku',
  'kiina',
  'kane-ra',
  'komau',
  'hydraxon',
  'mahri',
  'ga-suva',
  'hagah',
  'ackar',
  'vican',
  'atero',
  'waikiru',
  'brakas',
  'irnakk',
  'le-koro',
  'seeker',
  'vanisher',
  'baranus',
  'vezon',
  'taiki',
  'reidak',
  'bohrok',
  'matatu',
  'kikanalo',
  'roxtus',
  'braca',
  'skyboard',
  'pekka',
  'boxor',
  'artakha',
  'radiak',
  'uganu',
  'nilkuu',
  'maglya',
  'amaja',
  'visorak',
  'golyo',
  'melea',
  'korgot',
  'tuyet',
  'dosne',
  'click',
  'longfang',
  'metus',
  'dikapi',
  'garai',
  'archives',
  'hakann',
  'fikou',
  'nuhrii',
  'velika',
  'rahkshi',
  'pohatu',
  'pehkui',
  'baterra',
  'exo-toa',
  'gukko',
  'odina',
  'ko-suva',
  'thulox',
  'vohon',
  'krana',
  'manas',
  'arktinen',
  'ussalry',
  'primal',
  'nui-jaga',
  'muaka',
  'orkan',
  'pelagia',
  'tyrant',
  'bionicle',
  'kaxium',
  'bingzak',
  'lhikan',
  'niazesk',
  'botar',
  'zakaz',
  'ekimu',
  'stelt',
  'pouks',
  'spikit',
  'moto-hub',
  'agori',
  'ussanui',
  'takanuva',
  'mangaia',
  'po-kini',
  'amaya',
  'kirop',
  'le-wahi',
  'pahrak',
  'hydruka',
  'berix',
  'acidfly',
  'vorzakh',
  'su-kal',
  'jaatikko',
  'sentrakh',
  'pridak',
  'zivon',
  'le-suva',
  'calix',
  'spinax',
  'defilak',
  'tahnok',
  'keahi',
  'lerahk',
  'widget',
  'tanma',
  'piraka',
  'hewkii',
  'ancient',
  'okoto',
  'destral',
  'triglax',
  'kraahkan',
  'kongu',
  'mistika',
  'panrahk',
  'avohkah',
  'helryx',
  'dweller',
  'rockoh',
  'macku',
  'tamaru',
  'spiriah',
  'nivawk',
  'ga-metru',
  'rahaga',
  'hahnah',
  'sidorak',
  'teridax',
  'stronius',
  'lesovikk',
  'gresh',
  'vezok',
  'ignalu',
  'umarak',
  'ko-metru',
  'nidhiki',
  'keerakh',
  'tracker',
  'norik',
  'skirmix',
  'varian',
  'marka',
  'kinloka',
  'wairuha',
  'zaktan',
  'keystone',
  'iruini',
  'sahmad',
  'ko-wahi',
  'volitak',
  'vhisola',
  'krekka',
  'vorox',
  'kraawa',
  'kopeke',
  'makuta',
  'xa-kal',
  'daxia',
  'krahka',
  'kyrehx',
  'chiara',
  'pakastaa',
  'savage',
  'onu-suva',
  'taipu',
  'nireta',
  'piatra',
  'axato',
  'droton',
  'mukau',
  'felnas',
  'mana-ko',
  'protodax',
  'oohnorak',
  'gatherer',
  'ta-kini',
  'tri-claw',
  'toudo',
  'avohkii',
  'zemya',
  'ja-kal',
  'carapar',
  'le-kini',
  'de-koro',
  'ga-koro',
  'tohunga',
  'daikau',
  'kamen',
  'midak',
  'cendox',
  'azibo',
  'idris',
  'iconox',
  'po-koro',
  'comet',
  'ignika',
  'tehutti',
  'kollorak',
  'burnak',
  'boggarak',
  'agarak',
  'solek',
  'kraahu',
  'ta-suva',
  'scarabax',
  'coliseum',
  'angonce',
  'antroz',
  'gahdok',
  'tahtorak',
  'heremus',
  'phantom',
  'tarduk',
  'makoki',
  'ga-kini',
  'fenrakk',
  'kodan',
  'tajun',
  'korusca',
  'keelerak',
  'takadox',
  'turaga',
  'faxon',
  'uniter',
  'gavla',
  'vu-kal',
  'harvali',
  'lurker',
  'vizuna',
  'tarix',
  'akamu',
  'suukorak',
  'lohrak',
  'balta',
  'flammik',
  'kojol',
  'x-glider',
  'phantoka',
  'karzahni',
  'bomonga',
  'boreas',
  'garan',
  'aiyetoro',
  'keetongu',
  'onu-wahi',
  'axonn',
  'za-kal',
  'onewa',
  'gafna',
  'vulcanus',
  'aodhan',
  'orkahm',
  'jaller',
  'icarax',
  'epena',
  'kardas',
  'matoran',
  'matoro',
  'mahiki',
  'nobua',
  'ussal',
  'udapo',
  'nuparu',
  'takua',
  'strakk',
  'ta-wahi',
  'kolhii',
  'metru',
  'mantax',
  'jetrax',
  'minion',
  'roodaka',
  'akmuo',
  'onu-koro',
  'kohrak',
  'vatuka',
  'kuma-nui',
  'likus',
  'damek',
  'atakus',
  'le-metru',
  'guurahk',
  'raanu',
  'axalara',
  'narmoto',
  'vahki',
  'artidax',
  'mavrah',
  'thornax',
  'owaki',
  'nynrah',
  'olmak',
  'ca-kal',
  'hikaki',
  'okoth',
  'gaaki',
  'spinner',
  'motara',
  'makika',
  'ga-wahi',
  'mamru',
  'kakama',
  'surel',
  'gorast',
  'jerbraz',
  'ghekula',
  'sarda',
  'vortixx',
  'ko-koro',
  'vohtarak',
  'hordika',
  'kylma',
  'akamai',
  'telluris',
  'hahli',
  'talvi',
  'kahgarak',
  'shasa',
  'melum',
  'annona',
  'skopio',
  'tryna',
  'onu-kini',
  'kalmah',
  'johmak',
  'bordakh',
  'tunneler',
  'jagiri',
  'vakama',
  'graalok',
  'cordak',
  'kualsi',
  'kanohi',
  'mamoru',
  'nokama',
  'valmai',
  'ehlek',
  'miserix',
  'kini-nui',
  'kulta',
  'rorzakh',
  'rokreng',
  'guardian',
  'ketar',
  'vorahk',
  'kadin',
  'lariska',
  'kaukau',
  'bo-kal',
  'ko-kini',
  'arthron',
  'kantai',
  'ta-koro',
  'voporak',
  'bumonda',
  'kavinika',
  'jovan',
  'ehrye',
  'takea',
  'reysa',
  'olisi',
  'malum',
  'crast',
  'perditus',
  'branar',
  'krakua',
  'nixie',
  'zadakh',
  'po-wahi',
  'pakari',
  'roporak',
  'kralhi',
  'manutri',
  'sanok',
  'akilini',
  'lehvak',
  'kabrua',
  'keras',
  'whenua',
  'tridax',
  'mutran',
  'vamprah',
  'klakk',
  'skrall',
  'gadunka',
  'yo-kal',
  'po-suva',
  'kerato',
  'mohtrek',
  'tobduk',
  'brutaka',
  'ba-koro',
  'airweed',
  'exsidian',
  'fireroot',
  'pewku',
  'suletu',
  'terak',
  'dezalk',
  'marendar',
  'ta-metru',
  'takara',
  'gadjati',
  'kestora',
  'po-metru',
  'tesara',
  'krika',
  'chirox',
  'turahk',
  'tarakava',
  'kokkan',
  'makani',
  'kapura',
  'shelek',
  'kopaka',
  'kurahk',
  'izotor',
  'silence',
  'kiril',
  'zaria',
  'nocturn',
  'skakdi',
  'ngalawa',
  'nui-rama',
  'mamuk',
  'troller',
  'inika',
  'vastus',
  'mazeka',
  'pokawi',
  'kirbold',
  'akida',
  'nuhvok',
  'kivoda',
  'bahrag',
  'piruk',
  'barraki',
  'kranua',
  'buzkayo',
  'maxilos',
  'sanctum',
  'nuurakh',
  'vultraz',
  'umbra',
  'amphibax',
  'gaardus',
  'dormus',
  'brander',
  'photok',
  'tuuli',
  'kirbraz',
  'gahlok',
  'epolim',
  'hapaka',
  //////////////////////////////////////////////////////////////////////////////
  'cahdok',
  'manas',
  'kalama',
  'tanma',
  'taipu',
  'lurker',
  'kakama',
  'conjurer',
  'mutran',
  'rahkshi',
  'mangai',
  'tarakava',
  'matau',
  'okoth',
  'fikou',
  'graalok',
  'ussal',
  'rockoh',
  'tarix',
  'kopaka',
  'kolhii',
  'pohatu',
  'po-metru',
  'telluris',
  'hydraxon',
  'cordak',
  'komau',
  'annona',
  'brander',
  'vulcanus',
  'hakann',
  'gahlok',
  'norik',
  'vorox',
  'ca-kal',
  'kinloka',
  'zivon',
  'ko-kini',
  'matatu',
  'makuta',
  'skakdi',
  'kirop',
  'artakha',
  'perditus',
  'dosne',
  'tehutti',
  'piruk',
  'malum',
  'ussalry',
  'helryx',
  'kokkan',
  'turahk',
  'matoran',
  'mana-ko',
  'ancient',
  'mamru',
  'le-metru',
  'bo-kal',
  'ko-metru',
  'gatherer',
  'suukorak',
  'brutaka',
  'ta-kini',
  'ngalawa',
  'ga-wahi',
  'ko-wahi',
  'devourer',
  'vorahk',
  'droton',
  'crast',
  'spinner',
  'kraahu',
  'talvi',
  'ga-kini',
  'amaja',
  'hewkii',
  'ko-koro',
  'solek',
  'strakk',
  'vakama',
  'gresh',
  'kurahk',
  'keras',
  'keystone',
  'kadin',
  'epena',
  'tracker',
  'triglax',
  'jagiri',
  'makoki',
  'sentrakh',
  'melum',
  'maglya',
  'johmak',
  'iruini',
  'de-koro',
  'gahdok',
  'kongu',
  'troller',
  'harakeke',
  'phantom',
  'vhisola',
  'kamen',
  'radiak',
  'surel',
  'kualsi',
  'garai',
  'macku',
  'ehlek',
  'nixie',
  'avohkii',
  'takara',
  'bitil',
  'photok',
  'xa-kal',
  'amaya',
  'axalara',
  'zakaz',
  'orkahm',
  'archives',
  'lohrak',
  'tarduk',
  'boggarak',
  'nireta',
  'gaardus',
  'toudo',
  'piraka',
  'kuma-nui',
  'kraahkan',
  'nocturn',
  'roporak',
  'ga-metru',
  'kini-nui',
  'nilkuu',
  'vastus',
  'lerahk',
  'kiina',
  'zatth',
  'spinax',
  'onewa',
  'acidfly',
  'pekka',
  'okotan',
  'carapar',
  'ta-metru',
  'takadox',
  'skrall',
  'atero',
  'dekar',
  'lariska',
  'kahgarak',
  'jovan',
  'turaga',
  'bomonga',
  'jaatikko',
  'pakastaa',
  'olmak',
  'heremus',
  'nobua',
  'ehrye',
  'vezok',
  'pahrak',
  'golyo',
  'taiki',
  'vatuka',
  'avohkah',
  'takua',
  'lhikan',
  'volitak',
  'pokawi',
  'shasa',
  'lehvak',
  'fireroot',
  'varian',
  'kantai',
  'scarabax',
  'kaita',
  'axato',
  'akamai',
  'agarak',
  'click',
  'kyrehx',
  'nikila',
  'tryna',
  'tuyet',
  'kaxium',
  'matoro',
  'kanoka',
  'akamu',
  'hahnah',
  'mavrah',
  'vahki',
  'suletu',
  'za-kal',
  'seeker',
  'onu-suva',
  'le-suva',
  'izotor',
  'kualus',
  'hapaka',
  'gafna',
  'stronius',
  'yo-kal',
  'aiyetoro',
  'rokreng',
  'bumonda',
  'skyboard',
  'akaku',
  'kollorak',
  'krakua',
  'kraata',
  'destral',
  'ravager',
  'branar',
  'barraki',
  'mistika',
  'muaka',
  'ignalu',
  'charger',
  'hagah',
  'vizuna',
  'cendox',
  'zaria',
  'defilak',
  'bohrok',
  'mamuk',
  'dweller',
  'baranus',
  'skopio',
  'mangaia',
  'guardian',
  'miserix',
  'metus',
  'pelagia',
  'irnakk',
  'axonn',
  'krana',
  'udapo',
  'tahnok',
  'po-koro',
  'piatra',
  'vohtarak',
  'kraatu',
  'mazeka',
  'exo-toa',
  'tyrant',
  'kestora',
  'raanu',
  'iconox',
  'onu-wahi',
  'angonce',
  'manutri',
  'kailani',
  'onepu',
  'braca',
  'ekimu',
  'epolim',
  'keahi',
  'airweed',
  'ta-suva',
  'dezalk',
  'owaki',
  'ketar',
  'gukko',
  'mohtrek',
  'ranama',
  'daxia',
  'sanok',
  'ahkmou',
  'nui-rama',
  'zamor',
  'coliseum',
  'ba-koro',
  'kodan',
  'ga-koro',
  'faxon',
  'comet',
  'savage',
  'trinuma',
  'silence',
  'bordakh',
  'sidorak',
  'kalmah',
  'tri-claw',
  'berix',
  'dormus',
  'aodhan',
  'pewku',
  'reidak',
  'rorzakh',
  'guurahk',
  'vamprah',
  'gorast',
  'kabrua',
  'zemya',
  'fenrakk',
  'ko-suva',
  'keetongu',
  'kopeke',
  'metru',
  'ga-suva',
  'motara',
  'akilini',
  'nuurakh',
  'odina',
  'zyglak',
  'vu-kal',
  'kerato',
  'kojol',
  'hikaki',
  'phantoka',
  'po-suva',
  'botar',
  'tridax',
  'thulox',
  'keelerak',
  'bingzak',
  'tahtorak',
  'ghekula',
  'kanohi',
  'boxor',
  'hordika',
  'hydruka',
  'tuuli',
  'ackar',
  'umarak',
  'artidax',
  'x-glider',
  'tesara',
  'vortixx',
  'le-wahi',
  'bionicle',
  'zadakh',
  'arthron',
  'kraawa',
  'korgot',
  'gavla',
  'takea',
  'ta-wahi',
  'keerakh',
  'pouks',
  'vohon',
  'nivawk',
  'gadunka',
  'kranua',
  'rahaga',
  'calix',
  'onu-koro',
  'tajun',
  'pakari',
  'buzkayo',
  'chirox',
  'skirmix',
  'kulta',
  'terak',
  'niazesk',
  'rhotuka',
  'reysa',
  'arktinen',
  'likus',
  'amphibax',
  'vanisher',
  'marendar',
  'su-kal',
  'nidhiki',
  'tamaru',
  'widget',
  'longfang',
  'nuhvok',
  'marka',
  'midak',
  'dikapi',
  'mahiki',
  'tohunga',
  'minion',
  'devourer',
  'makika',
  'voporak',
  'krika',
  'korusca',
  'atakus',
  'maxilos',
  'le-koro',
  'mamoru',
  'stelt',
  'ja-kal',
  'nuhrii',
  'gaaki',
  'felnas',
  'kirbraz',
  'orkan',
  'balta',
  'akida',
  'makani',
  'jetrax',
  'pehkui',
  'vultraz',
  'ussanui',
  'kane-ra',
  'jaller',
  'krekka',
  'burnak',
  'inika',
  'kavinika',
  'waikiru',
  'idris',
  'uganu',
  'vican',
  'lesovikk',
  'whenua',
  'po-wahi',
  'sahmad',
  'roxtus',
  'thornax',
  'morak',
  'sanctum',
  'teridax',
  'kikanalo',
  'kiril',
  'codrex',
  'le-kini',
  'icarax',
  'klakk',
  'kardas',
  'akmuo',
  'spiriah',
  'kaukau',
  'kirbold',
  'karzahni',
  'visorak',
  'antroz',
  'brakas',
  'spikit',
  'kapura',
  'oohnorak',
  'shelek',
  'primal',
  'azibo',
  'baterra',
  'bahrag',
  'daikau',
  'nynrah',
  'umbra',
  'tunneler',
  'nokama',
  'po-kini',
  'sarda',
  'damek',
  'vezon',
  'pridak',
  'agori',
  'kohrak',
  'etoku',
  'olisi',
  'vorzakh',
  'mantax',
  'nektann',
  'chiara',
  'flammik',
  'jutlin',
  'ignika',
  'kylma',
  'mimic',
  'zaktan',
  'kralhi',
  'krahka',
  'valmai',
  'nui-jaga',
  'nuparu',
  'ta-koro',
  'hahli',
  'onu-kini',
  'uniter',
  'protodax',
  'takanuva',
  'jerbraz',
  'gadjati',
  'mahri',
  'velika',
  'roodaka',
  'okoto',
  'sanso',
  'harvali',
  'boreas',
  'tobduk',
  'narmoto',
  'panrahk',
  'garan',
  'melea',
  'exsidian',
  'mukau',
  'kivoda',
  'moto-hub',
  'certavus',
  'wairuha',
  //////////////////////////////////////////////////////////////////////////////
  'krika',
  'moto-hub',
  'lerahk',
  'melum',
  'vorox',
  'zyglak',
  'okotan',
  'spinax',
  'reysa',
  'tarduk',
  'roporak',
  'tyrant',
  'takea',
  'click',
  'kanoka',
  'kulta',
  'teridax',
  'tobduk',
  'baranus',
  'krekka',
  'kralhi',
  'kraawa',
  'ga-wahi',
  'shasa',
  'harakeke',
  'pakari',
  'rockoh',
  'kojol',
  'tehutti',
  'tahnok',
  'bo-kal',
  'kongu',
  'dezalk',
  'rorzakh',
  'baterra',
  'flammik',
  'arthron',
  'ta-koro',
  'izotor',
  'bohrok',
  'onu-suva',
  'ga-suva',
  'kardas',
  'stelt',
  'tarix',
  'suletu',
  'zamor',
  'ancient',
  'zaktan',
  'onepu',
  'akida',
  'tryna',
  'irnakk',
  'axalara',
  'nokama',
  'skirmix',
  'hordika',
  'devourer',
  'xa-kal',
  'kraata',
  'makika',
  'makani',
  'dosne',
  'bumonda',
  'manutri',
  'pahrak',
  'maxilos',
  'po-suva',
  'mutran',
  'varian',
  'amaya',
  'vohon',
  'gaaki',
  'ko-kini',
  'strakk',
  'keetongu',
  'tridax',
  'conjurer',
  'artakha',
  'etoku',
  'kailani',
  'buzkayo',
  'bitil',
  'akamu',
  'akmuo',
  'takanuva',
  'hikaki',
  'malum',
  'pakastaa',
  'vorahk',
  'toudo',
  'mohtrek',
  'sarda',
  'heremus',
  'karzahni',
  'kohrak',
  'matoro',
  'gaardus',
  'skyboard',
  'miserix',
  'lohrak',
  'comet',
  'longfang',
  'surel',
  'tanma',
  'ekimu',
  'boggarak',
  'udapo',
  'kranua',
  'kalama',
  'nilkuu',
  'vizuna',
  'exsidian',
  'nui-rama',
  'bomonga',
  'muaka',
  'savage',
  'kualus',
  'ketar',
  'garan',
  'braca',
  'avohkah',
  'po-metru',
  'skrall',
  'kikanalo',
  'agori',
  'nobua',
  'tesara',
  'metus',
  'droton',
  'sanctum',
  'golyo',
  'kokkan',
  'trinuma',
  'kantai',
  'tamaru',
  'faxon',
  'ba-koro',
  'za-kal',
  'akaku',
  'kaxium',
  'oohnorak',
  'thornax',
  'vatuka',
  'lurker',
  'pelagia',
  'antroz',
  'photok',
  'gavla',
  'kirop',
  'johmak',
  'kylma',
  'kraahu',
  'nuparu',
  'kavinika',
  'cendox',
  'mamru',
  'kopeke',
  'zatth',
  'telluris',
  'pouks',
  'ehrye',
  'kirbraz',
  'ta-kini',
  'nocturn',
  'epena',
  'archives',
  'krana',
  'kollorak',
  'kiina',
  'scarabax',
  'axato',
  'garai',
  'gadunka',
  'mukau',
  'troller',
  'piruk',
  'mavrah',
  'amaja',
  'hapaka',
  'po-koro',
  'akilini',
  'zadakh',
  'vamprah',
  'kraatu',
  'umarak',
  'exo-toa',
  'krahka',
  'aodhan',
  'vohtarak',
  'takua',
  'vakama',
  'rahkshi',
  'odina',
  'shelek',
  'tri-claw',
  'phantoka',
  'ta-suva',
  'charger',
  'mangai',
  'waikiru',
  'kamen',
  'bingzak',
  'chiara',
  'manas',
  'gresh',
  'marendar',
  'kiril',
  'hagah',
  'boreas',
  'jovan',
  'jagiri',
  'mangaia',
  'aiyetoro',
  'pridak',
  'takadox',
  'kolhii',
  'cordak',
  'morak',
  'ussalry',
  'gorast',
  'axonn',
  'boxor',
  'iconox',
  'vahki',
  'krakua',
  'phantom',
  'seeker',
  'kabrua',
  'daikau',
  'inika',
  'rhotuka',
  'onewa',
  'coliseum',
  'po-wahi',
  'mana-ko',
  'turaga',
  'valmai',
  'tuyet',
  'po-kini',
  'gahlok',
  'branar',
  'lesovikk',
  'brakas',
  'ahkmou',
  'makuta',
  'velika',
  'zivon',
  'ko-suva',
  'nuhrii',
  'certavus',
  'tracker',
  'keelerak',
  'le-wahi',
  'annona',
  'tohunga',
  'harvali',
  'yo-kal',
  'zaria',
  'midak',
  'kestora',
  'akamai',
  'widget',
  'nuhvok',
  'nivawk',
  'icarax',
  'ga-metru',
  'ko-metru',
  'orkahm',
  'roodaka',
  'panrahk',
  'avohkii',
  'hewkii',
  'azibo',
  'artidax',
  'visorak',
  'pehkui',
  'mazeka',
  'onu-wahi',
  'olisi',
  'ta-metru',
  'keerakh',
  'kanohi',
  'sidorak',
  'kinloka',
  'keystone',
  'kalmah',
  'hakann',
  'roxtus',
  'mantax',
  'ngalawa',
  'amphibax',
  'turahk',
  'matau',
  'fireroot',
  'matoran',
  'hahnah',
  'ga-kini',
  'metru',
  'kivoda',
  'idris',
  'gafna',
  'norik',
  'burnak',
  'jetrax',
  'owaki',
  'hydraxon',
  'matatu',
  'codrex',
  'le-metru',
  'triglax',
  'uniter',
  'rahaga',
  'mamoru',
  'mamuk',
  'helryx',
  'ackar',
  'spikit',
  'piraka',
  'jerbraz',
  'minion',
  'daxia',
  'berix',
  'suukorak',
  'ehlek',
  'fenrakk',
  'skakdi',
  'mistika',
  'ussanui',
  'tahtorak',
  'pohatu',
  'kapura',
  'ignika',
  'taipu',
  'tarakava',
  'narmoto',
  'voporak',
  'hydruka',
  'ca-kal',
  'mimic',
  'taiki',
  'dormus',
  'sahmad',
  'onu-koro',
  'jutlin',
  'ga-koro',
  'protodax',
  'whenua',
  'gatherer',
  'nikila',
  'ko-koro',
  'silence',
  'lehvak',
  'korgot',
  'jaatikko',
  'pekka',
  'skopio',
  'nynrah',
  'epolim',
  'vhisola',
  'arktinen',
  'olmak',
  'umbra',
  'zakaz',
  'bionicle',
  'guurahk',
  'perditus',
  'le-suva',
  'damek',
  'vezon',
  'keahi',
  'vorzakh',
  'kurahk',
  'gukko',
  'ghekula',
  'volitak',
  'brutaka',
  'ignalu',
  'nektann',
  'makoki',
  'klakk',
  'kerato',
  'kyrehx',
  'iruini',
  'calix',
  'niazesk',
  'nui-jaga',
  'gadjati',
  'kualsi',
  'ussal',
  'terak',
  'ranama',
  'komau',
  'vortixx',
  'airweed',
  'bordakh',
  'x-glider',
  'atero',
  'melea',
  'le-kini',
  'talvi',
  'fikou',
  'vanisher',
  'reidak',
  'kane-ra',
  'zemya',
  'graalok',
  'vastus',
  'kuma-nui',
  'radiak',
  'spinner',
  'tunneler',
  'kaukau',
  'kraahkan',
  'pewku',
  'orkan',
  'ja-kal',
  'pokawi',
  'cahdok',
  'lhikan',
  'barraki',
  'kaita',
  'tuuli',
  'mahiki',
  'carapar',
  'kadin',
  'ta-wahi',
  'botar',
  'dekar',
  'balta',
  'onu-kini',
  'marka',
  'guardian',
  'keras',
  'nixie',
  'vezok',
  'bahrag',
  'solek',
  'dweller',
  'mahri',
  'sentrakh',
  'chirox',
  'kopaka',
  'angonce',
  'primal',
  'lariska',
  'le-koro',
  'thulox',
  'wairuha',
  'vultraz',
  'kini-nui',
  'agarak',
  'atakus',
  'ko-wahi',
  'brander',
  'vu-kal',
  'defilak',
  'okoto',
  'jaller',
  'sanok',
  'korusca',
  'felnas',
  'vulcanus',
  'spiriah',
  'takara',
  'raanu',
  'devourer',
  'nireta',
  'kakama',
  'motara',
  'likus',
  'vican',
  'hahli',
  'uganu',
  'piatra',
  'crast',
  'maglya',
  'nidhiki',
  'kodan',
  'de-koro',
  'kahgarak',
  'okoth',
  'gahdok',
  'macku',
  'rokreng',
  'su-kal',
  'destral',
  'stronius',
  'acidfly',
  'ravager',
  'kirbold',
  'nuurakh',
  'tajun',
  'sanso',
  'dikapi',
  //////////////////////////////////////////////////////////////////////////////
  'whenua',
  'airweed',
  'keetongu',
  'pakastaa',
  'su-kal',
  'angonce',
  'ehrye',
  'kerato',
  'droton',
  'defilak',
  'ussalry',
  'bumonda',
  'coliseum',
  'korusca',
  'charger',
  'tarix',
  'tobduk',
  'zamor',
  'mana-ko',
  'lerahk',
  'mazeka',
  'fireroot',
  'kalama',
  'nynrah',
  'miserix',
  'kojol',
  'sentrakh',
  'radiak',
  'faxon',
  'rockoh',
  'turahk',
  'sarda',
  'voporak',
  'ga-kini',
  'stelt',
  'lehvak',
  'okoth',
  'pakari',
  'balta',
  'kalmah',
  'vakama',
  'krika',
  'po-koro',
  'buzkayo',
  'makika',
  'arthron',
  'tryna',
  'matau',
  'kaukau',
  'comet',
  'nui-jaga',
  'spikit',
  'roporak',
  'bo-kal',
  'pouks',
  'spinax',
  'skrall',
  'lhikan',
  'ussal',
  'kohrak',
  'nilkuu',
  'annona',
  'kollorak',
  'nobua',
  'panrahk',
  'sahmad',
  'mahiki',
  'hydruka',
  'kailani',
  'certavus',
  'le-suva',
  'taipu',
  'odina',
  'gorast',
  'keerakh',
  'kualsi',
  'krana',
  'kirbraz',
  'strakk',
  'vorox',
  'burnak',
  'makoki',
  'zaria',
  'iruini',
  'gatherer',
  'ehlek',
  'ga-metru',
  'lesovikk',
  'kiril',
  'rahaga',
  'takadox',
  'vortixx',
  'dikapi',
  'krakua',
  'tehutti',
  'avohkii',
  'rorzakh',
  'po-suva',
  'manutri',
  'kualus',
  'azibo',
  'varian',
  'x-glider',
  'kraahkan',
  'epolim',
  'solek',
  'vhisola',
  'kopaka',
  'kanoka',
  'kapura',
  'izotor',
  'roxtus',
  'savage',
  'dosne',
  'tunneler',
  'pekka',
  'devourer',
  'volitak',
  'owaki',
  'aodhan',
  'bomonga',
  'ga-wahi',
  'krahka',
  'dweller',
  'kaxium',
  'po-wahi',
  'nidhiki',
  'narmoto',
  'talvi',
  'suukorak',
  'marendar',
  'akaku',
  'midak',
  'klakk',
  'matoran',
  'keahi',
  'ko-metru',
  'ta-suva',
  'shasa',
  'olisi',
  'pahrak',
  'akida',
  'pohatu',
  'nui-rama',
  'terak',
  'sanok',
  'artidax',
  'hydraxon',
  'akamai',
  'skirmix',
  'tesara',
  'ta-metru',
  'onu-wahi',
  'onu-suva',
  'kadin',
  'reysa',
  'tahtorak',
  'ko-wahi',
  'click',
  'vohtarak',
  'kakama',
  'kurahk',
  'vultraz',
  'nuhvok',
  'epena',
  'thulox',
  'gukko',
  'calix',
  'kamen',
  'metus',
  'brutaka',
  'vamprah',
  'kantai',
  'mamuk',
  'dezalk',
  'niazesk',
  'harakeke',
  'harvali',
  'likus',
  'vorzakh',
  'sanctum',
  'pokawi',
  'dekar',
  'morak',
  'arktinen',
  'iconox',
  'taiki',
  'matatu',
  'daikau',
  'kini-nui',
  'ko-kini',
  'hordika',
  'vohon',
  'kolhii',
  'tahnok',
  'malum',
  'gafna',
  'etoku',
  'ahkmou',
  'kinloka',
  'tamaru',
  'bohrok',
  'bordakh',
  'kabrua',
  'amphibax',
  'avohkah',
  'visorak',
  'turaga',
  'melum',
  'kaita',
  'xa-kal',
  'vorahk',
  'uniter',
  'okotan',
  'vanisher',
  'trinuma',
  'zakaz',
  'bahrag',
  'branar',
  'le-koro',
  'jovan',
  'sidorak',
  'kokkan',
  'waikiru',
  'tracker',
  'keelerak',
  'jetrax',
  'damek',
  'gavla',
  'ancient',
  'po-metru',
  'seeker',
  'nuurakh',
  'umarak',
  'axalara',
  'kodan',
  'cendox',
  'rhotuka',
  'mohtrek',
  'graalok',
  'boxor',
  'tarakava',
  'ta-wahi',
  'ba-koro',
  'teridax',
  'crast',
  'phantom',
  'jutlin',
  'conjurer',
  'barraki',
  'pelagia',
  'kirop',
  'amaya',
  'protodax',
  'axato',
  'za-kal',
  'takua',
  'berix',
  'takanuva',
  'melea',
  'vastus',
  'zivon',
  'skopio',
  'umbra',
  'bionicle',
  'brander',
  'surel',
  'spinner',
  'onu-kini',
  'cahdok',
  'toudo',
  'skakdi',
  'le-metru',
  'vu-kal',
  'krekka',
  'dormus',
  'brakas',
  'ussanui',
  'vican',
  'uganu',
  'photok',
  'makuta',
  'jagiri',
  'mangaia',
  'thornax',
  'okoto',
  'mamru',
  'vezok',
  'boreas',
  'kavinika',
  'orkahm',
  'lohrak',
  'telluris',
  'matoro',
  'agarak',
  'kraawa',
  'hikaki',
  'carapar',
  'minion',
  'gadjati',
  'akamu',
  'maxilos',
  'primal',
  'shelek',
  'ketar',
  'olmak',
  'onu-koro',
  'muaka',
  'antroz',
  'gahlok',
  'maglya',
  'metru',
  'udapo',
  'lurker',
  'zadakh',
  'heremus',
  'chirox',
  'kyrehx',
  'piruk',
  'macku',
  'tuyet',
  'kanohi',
  'ranama',
  'inika',
  'karzahni',
  'nokama',
  'atero',
  'tajun',
  'lariska',
  'ko-suva',
  'troller',
  'moto-hub',
  'tanma',
  'wairuha',
  'rokreng',
  'zatth',
  'le-kini',
  'ignalu',
  'silence',
  'mutran',
  'ko-koro',
  'akmuo',
  'pewku',
  'helryx',
  'ta-kini',
  'roodaka',
  'de-koro',
  'mimic',
  'garan',
  'flammik',
  'tridax',
  'mavrah',
  'longfang',
  'vahki',
  'piraka',
  'nikila',
  'icarax',
  'jaatikko',
  'ekimu',
  'onewa',
  'idris',
  'kopeke',
  'ravager',
  'yo-kal',
  'triglax',
  'motara',
  'raanu',
  'makani',
  'hapaka',
  'nireta',
  'keystone',
  'skyboard',
  'gaardus',
  'kahgarak',
  'ja-kal',
  'hagah',
  'tri-claw',
  'agori',
  'gahdok',
  'kestora',
  'exsidian',
  'perditus',
  'hahnah',
  'nixie',
  'zaktan',
  'rahkshi',
  'kirbold',
  'irnakk',
  'jaller',
  'mantax',
  'kongu',
  'reidak',
  'mistika',
  'sanso',
  'gresh',
  'kiina',
  'vezon',
  'hahli',
  'po-kini',
  'ga-suva',
  'hewkii',
  'ca-kal',
  'codrex',
  'kivoda',
  'ga-koro',
  'tohunga',
  'akilini',
  'johmak',
  'vizuna',
  'korgot',
  'artakha',
  'kulta',
  'tuuli',
  'nocturn',
  'bingzak',
  'nektann',
  'bitil',
  'acidfly',
  'marka',
  'kraata',
  'atakus',
  'onepu',
  'gadunka',
  'kikanalo',
  'tyrant',
  'orkan',
  'golyo',
  'spiriah',
  'ngalawa',
  'nivawk',
  'mamoru',
  'kane-ra',
  'felnas',
  'piatra',
  'exo-toa',
  'kralhi',
  'hakann',
  'daxia',
  'garai',
  'pehkui',
  'ignika',
  'vatuka',
  'mahri',
  'amaja',
  'nuhrii',
  'chiara',
  'ta-koro',
  'fenrakk',
  'manas',
  'kranua',
  'gaaki',
  'stronius',
  'takea',
  'ackar',
  'kraatu',
  'suletu',
  'kylma',
  'ghekula',
  'norik',
  'braca',
  'pridak',
  'valmai',
  'devourer',
  'boggarak',
  'mukau',
  'velika',
  'phantoka',
  'kardas',
  'guurahk',
  'mangai',
  'zyglak',
  'kraahu',
  'widget',
  'guardian',
  'oohnorak',
  'archives',
  'axonn',
  'aiyetoro',
  'komau',
  'fikou',
  'baranus',
  'tarduk',
  'jerbraz',
  'vulcanus',
  'kuma-nui',
  'scarabax',
  'botar',
  'cordak',
  'takara',
  'baterra',
  'destral',
  'zemya',
  'le-wahi',
  'keras',
  'nuparu',
  //////////////////////////////////////////////////////////////////////////////
  'owaki',
  'akida',
  'reysa',
  'kakama',
  'keystone',
  'pelagia',
  'chiara',
  'pohatu',
  'skrall',
  'jagiri',
  'codrex',
  'kojol',
  'sahmad',
  'mamru',
  'bingzak',
  'kokkan',
  'cahdok',
  'teridax',
  'sidorak',
  'kirbraz',
  'mangai',
  'kraata',
  'mohtrek',
  'ko-koro',
  'vhisola',
  'takea',
  'vultraz',
  'umarak',
  'xa-kal',
  'krika',
  'scarabax',
  'ta-koro',
  'marendar',
  'motara',
  'sanctum',
  'vu-kal',
  'vanisher',
  'kralhi',
  'odina',
  'akaku',
  'kylma',
  'ko-kini',
  'taiki',
  'phantom',
  'nynrah',
  'miserix',
  'kopaka',
  'mangaia',
  'ta-metru',
  'nilkuu',
  'ranama',
  'onu-koro',
  'ga-koro',
  'keahi',
  'sentrakh',
  'kabrua',
  'annona',
  'wairuha',
  'de-koro',
  'kirop',
  'epolim',
  'click',
  'savage',
  'garan',
  'ngalawa',
  'exo-toa',
  'metru',
  'matoran',
  'krakua',
  'kranua',
  'agori',
  'kiril',
  'reidak',
  'stronius',
  'turahk',
  'tesara',
  'dweller',
  'harakeke',
  'kuma-nui',
  'jaatikko',
  'makika',
  'orkahm',
  'kantai',
  'marka',
  'droton',
  'zakaz',
  'kopeke',
  'ja-kal',
  'kongu',
  'metus',
  'radiak',
  'spinax',
  'mukau',
  'tuuli',
  'golyo',
  'tanma',
  'melea',
  'shelek',
  'jerbraz',
  'onepu',
  'barraki',
  'nocturn',
  'skakdi',
  'ko-suva',
  'epena',
  'idris',
  'kailani',
  'rahaga',
  'bohrok',
  'axonn',
  'gavla',
  'fenrakk',
  'sanso',
  'akilini',
  'mazeka',
  'carapar',
  'tobduk',
  'cendox',
  'onu-kini',
  'korusca',
  'antroz',
  'po-koro',
  'spinner',
  'vorox',
  'agarak',
  'rokreng',
  'tunneler',
  'mutran',
  'artakha',
  'varian',
  'le-metru',
  'trinuma',
  'gadjati',
  'olisi',
  'silence',
  'jaller',
  'ahkmou',
  'komau',
  'hahnah',
  'kane-ra',
  'nivawk',
  'zamor',
  'pouks',
  'kalmah',
  'kohrak',
  'mamoru',
  'kanoka',
  'gukko',
  'faxon',
  'nidhiki',
  'po-metru',
  'triglax',
  'graalok',
  'ussanui',
  'hakann',
  'kivoda',
  'hydraxon',
  'kikanalo',
  'fikou',
  'matau',
  'airweed',
  'takadox',
  'ca-kal',
  'vamprah',
  'keelerak',
  'bo-kal',
  'tehutti',
  'flammik',
  'waikiru',
  'terak',
  'damek',
  'muaka',
  'felnas',
  'orkan',
  'kodan',
  'ga-metru',
  'rhotuka',
  'dezalk',
  'zaria',
  'narmoto',
  'kardas',
  'mahiki',
  'tyrant',
  'macku',
  'le-suva',
  'kini-nui',
  'vezon',
  'guardian',
  'vastus',
  'conjurer',
  'kraawa',
  'bumonda',
  'harvali',
  'vorzakh',
  'tarduk',
  'axalara',
  'irnakk',
  'talvi',
  'piatra',
  'ussalry',
  'aiyetoro',
  'ehlek',
  'okoto',
  'za-kal',
  'charger',
  'sanok',
  'tamaru',
  'x-glider',
  'ancient',
  'lohrak',
  'raanu',
  'shasa',
  'kamen',
  'ghekula',
  'pakastaa',
  'yo-kal',
  'aodhan',
  'troller',
  'hordika',
  'lurker',
  'dekar',
  'baranus',
  'dormus',
  'coliseum',
  'kirbold',
  'ekimu',
  'roporak',
  'keerakh',
  'mimic',
  'le-kini',
  'rorzakh',
  'makani',
  'burnak',
  'vezok',
  'hydruka',
  'moto-hub',
  'azibo',
  'daikau',
  'brander',
  'archives',
  'gresh',
  'avohkah',
  'buzkayo',
  'ga-suva',
  'acidfly',
  'helryx',
  'malum',
  'onewa',
  'perditus',
  'suletu',
  'ba-koro',
  'ussal',
  'udapo',
  'keras',
  'lesovikk',
  'braca',
  'ackar',
  'spikit',
  'maglya',
  'hewkii',
  'olmak',
  'bordakh',
  'takara',
  'avohkii',
  'heremus',
  'telluris',
  'balta',
  'panrahk',
  'zyglak',
  'kraatu',
  'kalama',
  'seeker',
  'zemya',
  'ga-kini',
  'nobua',
  'hapaka',
  'manutri',
  'hagah',
  'skyboard',
  'gorast',
  'takanuva',
  'kolhii',
  'boxor',
  'izotor',
  'garai',
  'axato',
  'baterra',
  'le-koro',
  'klakk',
  'whenua',
  'keetongu',
  'tohunga',
  'daxia',
  'akamu',
  'kadin',
  'uniter',
  'mavrah',
  'po-suva',
  'melum',
  'comet',
  'kualus',
  'kraahkan',
  'longfang',
  'nui-jaga',
  'pehkui',
  'angonce',
  'johmak',
  'kaukau',
  'akmuo',
  'lehvak',
  'korgot',
  'gadunka',
  'amaya',
  'amphibax',
  'brakas',
  'kraahu',
  'gafna',
  'nuparu',
  'niazesk',
  'iconox',
  'pakari',
  'mahri',
  'takua',
  'oohnorak',
  'voporak',
  'nuurakh',
  'pewku',
  'nuhvok',
  'mamuk',
  'nektann',
  'fireroot',
  'kerato',
  'taipu',
  'kavinika',
  'pahrak',
  'karzahni',
  'norik',
  'hahli',
  'zivon',
  'likus',
  'photok',
  'devourer',
  'matoro',
  'okotan',
  'bahrag',
  'maxilos',
  'nokama',
  'piruk',
  'gatherer',
  'icarax',
  'lhikan',
  'hikaki',
  'skirmix',
  'devourer',
  'skopio',
  'etoku',
  'vulcanus',
  'cordak',
  'vican',
  'chirox',
  'tajun',
  'tarakava',
  'bomonga',
  'lariska',
  'vakama',
  'zadakh',
  'guurahk',
  'akamai',
  'solek',
  'gahdok',
  'roxtus',
  'surel',
  'amaja',
  'su-kal',
  'pekka',
  'mana-ko',
  'uganu',
  'ignika',
  'gaaki',
  'nui-rama',
  'certavus',
  'iruini',
  'artidax',
  'gahlok',
  'kualsi',
  'sarda',
  'tahnok',
  'manas',
  'ignalu',
  'ko-metru',
  'ta-wahi',
  'nikila',
  'piraka',
  'onu-suva',
  'zaktan',
  'stelt',
  'tarix',
  'nuhrii',
  'atero',
  'po-kini',
  'boggarak',
  'jovan',
  'lerahk',
  'vortixx',
  'mistika',
  'midak',
  'boreas',
  'kaita',
  'pokawi',
  'berix',
  'gaardus',
  'kulta',
  'destral',
  'thulox',
  'tridax',
  'thornax',
  'kyrehx',
  'protodax',
  'zatth',
  'krahka',
  'widget',
  'po-wahi',
  'mantax',
  'ta-suva',
  'nixie',
  'nireta',
  'arthron',
  'tuyet',
  'kanohi',
  'bitil',
  'makuta',
  'toudo',
  'kinloka',
  'dosne',
  'crast',
  'atakus',
  'vorahk',
  'kahgarak',
  'suukorak',
  'ketar',
  'morak',
  'umbra',
  'spiriah',
  'phantoka',
  'botar',
  'kaxium',
  'primal',
  'calix',
  'jetrax',
  'vahki',
  'valmai',
  'pridak',
  'bionicle',
  'volitak',
  'le-wahi',
  'okoth',
  'makoki',
  'ga-wahi',
  'krekka',
  'matatu',
  'brutaka',
  'krana',
  'velika',
  'strakk',
  'tri-claw',
  'kurahk',
  'dikapi',
  'vohtarak',
  'rahkshi',
  'tahtorak',
  'turaga',
  'kollorak',
  'exsidian',
  'arktinen',
  'tryna',
  'branar',
  'ehrye',
  'kapura',
  'tracker',
  'inika',
  'ta-kini',
  'rockoh',
  'kestora',
  'minion',
  'ravager',
  'vizuna',
  'kiina',
  'onu-wahi',
  'jutlin',
  'visorak',
  'vatuka',
  'vohon',
  'defilak',
  'roodaka',
  'ko-wahi',
  //////////////////////////////////////////////////////////////////////////////
  'braca',
  'kerato',
  'onu-kini',
  'po-wahi',
  'gatherer',
  'talvi',
  'nixie',
  'orkahm',
  'gadjati',
  'onewa',
  'iruini',
  'onepu',
  'turahk',
  'ghekula',
  'pewku',
  'avohkii',
  'visorak',
  'vu-kal',
  'reidak',
  'codrex',
  'agarak',
  'kopaka',
  'zemya',
  'kuma-nui',
  'nikila',
  'garan',
  'hydraxon',
  'keras',
  'valmai',
  'maglya',
  'tahtorak',
  'hikaki',
  'bordakh',
  'mana-ko',
  'skyboard',
  'kopeke',
  'vican',
  'hapaka',
  'hakann',
  'takadox',
  'widget',
  'gahlok',
  'jovan',
  'guurahk',
  'jaller',
  'perditus',
  'makuta',
  'johmak',
  'boxor',
  'devourer',
  'chirox',
  'odina',
  'cordak',
  'kranua',
  'nocturn',
  'kavinika',
  'zaria',
  'lehvak',
  'dosne',
  'spinner',
  'mohtrek',
  'bahrag',
  'midak',
  'keelerak',
  'tracker',
  'bo-kal',
  'tunneler',
  'mangai',
  'dormus',
  'korusca',
  'chiara',
  'muaka',
  'hahli',
  'tahnok',
  'brander',
  'uniter',
  'kongu',
  'olisi',
  'tobduk',
  'krahka',
  'mangaia',
  'vhisola',
  'kolhii',
  'minion',
  'axato',
  'kinloka',
  'sanctum',
  'vezok',
  'niazesk',
  'agori',
  'gafna',
  'kulta',
  'ekimu',
  'airweed',
  'rokreng',
  'irnakk',
  'akamu',
  'norik',
  'velika',
  'artakha',
  'daxia',
  'volitak',
  'vorahk',
  'lhikan',
  'kirbold',
  'jaatikko',
  'iconox',
  'marka',
  'kivoda',
  'vulcanus',
  'korgot',
  'idris',
  'klakk',
  'yo-kal',
  'azibo',
  'zivon',
  'manas',
  'pakari',
  'waikiru',
  'tryna',
  'sidorak',
  'ancient',
  'mimic',
  'jutlin',
  'carapar',
  'kahgarak',
  'bingzak',
  'nidhiki',
  'sahmad',
  'sarda',
  'kardas',
  'tajun',
  'tuuli',
  'po-koro',
  'zamor',
  'teridax',
  'kraatu',
  'kodan',
  'terak',
  'hahnah',
  'mamoru',
  'ussal',
  'kualus',
  'thornax',
  'suukorak',
  'mutran',
  'tesara',
  'mahiki',
  'rahaga',
  'kini-nui',
  'jagiri',
  'bitil',
  'flammik',
  'ko-suva',
  'nobua',
  'tuyet',
  'mamuk',
  'kaxium',
  'vohtarak',
  'arktinen',
  'click',
  'nynrah',
  'tamaru',
  'taipu',
  'kiril',
  'izotor',
  'maxilos',
  'akmuo',
  'ussanui',
  'sentrakh',
  'daikau',
  'vizuna',
  'faxon',
  'kokkan',
  'hewkii',
  'ko-metru',
  'macku',
  'keahi',
  'metus',
  'skakdi',
  'vezon',
  'vorzakh',
  'za-kal',
  'vorox',
  'ko-wahi',
  'tohunga',
  'fireroot',
  'ga-wahi',
  'tarakava',
  'guardian',
  'ga-koro',
  'exsidian',
  'gadunka',
  'makika',
  'annona',
  'kohrak',
  'ehrye',
  'kane-ra',
  'amaya',
  'kantai',
  'nuparu',
  'vatuka',
  'piatra',
  'pahrak',
  'mavrah',
  'tanma',
  'pridak',
  'amaja',
  'skopio',
  'akida',
  'bionicle',
  'lariska',
  'kestora',
  'kanoka',
  'felnas',
  'defilak',
  'mantax',
  'comet',
  'po-metru',
  'suletu',
  'pakastaa',
  'pohatu',
  'kraahkan',
  'kirbraz',
  'nilkuu',
  'ignalu',
  'takua',
  'fikou',
  'amphibax',
  'jerbraz',
  'pekka',
  'makoki',
  'krika',
  'antroz',
  'akamai',
  'tarduk',
  'kollorak',
  'kalama',
  'sanso',
  'uganu',
  'xa-kal',
  'likus',
  'tridax',
  'acidfly',
  'takea',
  'aodhan',
  'vamprah',
  'icarax',
  'berix',
  'umarak',
  'lurker',
  'piruk',
  'vohon',
  'zyglak',
  'mazeka',
  'tarix',
  'atero',
  'phantoka',
  'mukau',
  'kadin',
  'kaita',
  'barraki',
  'rhotuka',
  'taiki',
  'pouks',
  'tri-claw',
  'nireta',
  'heremus',
  'varian',
  'krakua',
  'garai',
  'matau',
  'conjurer',
  'krana',
  'ca-kal',
  'mistika',
  'ketar',
  'charger',
  'ackar',
  'le-wahi',
  'keystone',
  'rahkshi',
  'nuurakh',
  'pelagia',
  'vastus',
  'sanok',
  'okoth',
  'kojol',
  'karzahni',
  'gaardus',
  'bumonda',
  'onu-wahi',
  'vakama',
  'kualsi',
  'kirop',
  'le-koro',
  'kurahk',
  'roxtus',
  'motara',
  'epolim',
  'ta-wahi',
  'harakeke',
  'ngalawa',
  'exo-toa',
  'whenua',
  'angonce',
  'le-kini',
  'zatth',
  'boreas',
  'voporak',
  'ranama',
  'kanohi',
  'gahdok',
  'brakas',
  'po-kini',
  'nuhvok',
  'moto-hub',
  'ta-metru',
  'thulox',
  'dweller',
  'nuhrii',
  'po-suva',
  'skirmix',
  'x-glider',
  'skrall',
  'keetongu',
  'stelt',
  'seeker',
  'golyo',
  'longfang',
  'orkan',
  'radiak',
  'graalok',
  'su-kal',
  'owaki',
  'arthron',
  'olmak',
  'telluris',
  'hagah',
  'kylma',
  'coliseum',
  'tehutti',
  'gukko',
  'destral',
  'kailani',
  'komau',
  'matoro',
  'wairuha',
  'branar',
  'le-suva',
  'damek',
  'buzkayo',
  'kalmah',
  'narmoto',
  'scarabax',
  'toudo',
  'stronius',
  'nui-rama',
  'le-metru',
  'udapo',
  'morak',
  'zadakh',
  'ga-kini',
  'kabrua',
  'onu-suva',
  'fenrakk',
  'kyrehx',
  'axonn',
  'mamru',
  'onu-koro',
  'krekka',
  'jetrax',
  'botar',
  'primal',
  'shasa',
  'dezalk',
  'surel',
  'spiriah',
  'aiyetoro',
  'okoto',
  'hordika',
  'piraka',
  'ignika',
  'lohrak',
  'ta-suva',
  'nivawk',
  'strakk',
  'zakaz',
  'ko-koro',
  'kamen',
  'vultraz',
  'turaga',
  'makani',
  'kraahu',
  'ga-suva',
  'matoran',
  'kakama',
  'vortixx',
  'inika',
  'manutri',
  'reysa',
  'roporak',
  'gaaki',
  'droton',
  'metru',
  'gavla',
  'artidax',
  'archives',
  'gorast',
  'takara',
  'lesovikk',
  'raanu',
  'phantom',
  'vanisher',
  'devourer',
  'takanuva',
  'kapura',
  'pehkui',
  'tyrant',
  'keerakh',
  'balta',
  'dikapi',
  'miserix',
  'ta-kini',
  'ta-koro',
  'ko-kini',
  'melea',
  'bohrok',
  'oohnorak',
  'hydruka',
  'nokama',
  'roodaka',
  'panrahk',
  'protodax',
  'de-koro',
  'rorzakh',
  'helryx',
  'baranus',
  'kraata',
  'ga-metru',
  'troller',
  'melum',
  'zaktan',
  'dekar',
  'calix',
  'crast',
  'cahdok',
  'etoku',
  'avohkah',
  'brutaka',
  'solek',
  'photok',
  'silence',
  'kikanalo',
  'boggarak',
  'akilini',
  'savage',
  'harvali',
  'axalara',
  'kiina',
  'nektann',
  'ussalry',
  'akaku',
  'kraawa',
  'cendox',
  'vahki',
  'matatu',
  'malum',
  'gresh',
  'ja-kal',
  'rockoh',
  'trinuma',
  'mahri',
  'kralhi',
  'okotan',
  'baterra',
  'nui-jaga',
  'epena',
  'atakus',
  'marendar',
  'shelek',
  'bomonga',
  'ba-koro',
  'lerahk',
  'certavus',
  'spinax',
  'umbra',
  'burnak',
  'kaukau',
  'triglax',
  'ahkmou',
  'ravager',
  'pokawi',
  'ehlek',
  'spikit',
  //////////////////////////////////////////////////////////////////////////////
  'owaki',
  'kakama',
  'kaita',
  'akilini',
  'kestora',
  'norik',
  'protodax',
  'spinner',
  'stronius',
  'nektann',
  'gavla',
  'mamoru',
  'ignalu',
  'thulox',
  'skrall',
  'moto-hub',
  'karzahni',
  'kanohi',
  'mutran',
  'makuta',
  'kralhi',
  'harvali',
  'epolim',
  'ussalry',
  'keerakh',
  'lurker',
  'carapar',
  'metru',
  'etoku',
  'iruini',
  'garan',
  'le-suva',
  'kailani',
  'cendox',
  'krekka',
  'brakas',
  'kualsi',
  'ravager',
  'lehvak',
  'hahnah',
  'akamu',
  'hapaka',
  'radiak',
  'kojol',
  'tunneler',
  'nuparu',
  'mana-ko',
  'codrex',
  'pakari',
  'turahk',
  'dosne',
  'widget',
  'bo-kal',
  'pekka',
  'matau',
  'kranua',
  'annona',
  'talvi',
  'kraahkan',
  'kinloka',
  'kamen',
  'brander',
  'daikau',
  'vizuna',
  'gafna',
  'angonce',
  'valmai',
  'vakama',
  'pelagia',
  'mukau',
  'kaxium',
  'ta-koro',
  'ba-koro',
  'gorast',
  'sentrakh',
  'mamuk',
  'shelek',
  'nuhvok',
  'atakus',
  'kraahu',
  'kraata',
  'heremus',
  'takadox',
  'bumonda',
  'axalara',
  'hydraxon',
  'matoro',
  'guardian',
  'ghekula',
  'nuhrii',
  'piraka',
  'vorahk',
  'harakeke',
  'exo-toa',
  'tryna',
  'vahki',
  'udapo',
  'oohnorak',
  'ga-metru',
  'kerato',
  'olisi',
  'dweller',
  'varian',
  'melea',
  'keetongu',
  'burnak',
  'zivon',
  'perditus',
  'bahrag',
  'stelt',
  'sarda',
  'kokkan',
  'mistika',
  'scarabax',
  'skopio',
  'azibo',
  'reysa',
  'takara',
  'ja-kal',
  'roporak',
  'rhotuka',
  'surel',
  'jovan',
  'le-wahi',
  'cordak',
  'malum',
  'okoto',
  'jetrax',
  'nuurakh',
  'de-koro',
  'spiriah',
  'le-kini',
  'le-koro',
  'reidak',
  'jaatikko',
  'ackar',
  'izotor',
  'kirop',
  'suletu',
  'tyrant',
  'maglya',
  'boreas',
  'sanctum',
  'tracker',
  'bomonga',
  'pahrak',
  'arthron',
  'kyrehx',
  'korusca',
  'nidhiki',
  'vorox',
  'lerahk',
  'mahiki',
  'gresh',
  'whenua',
  'roodaka',
  'brutaka',
  'vanisher',
  'uganu',
  'aiyetoro',
  'kalama',
  'macku',
  'orkahm',
  'kahgarak',
  'zadakh',
  'buzkayo',
  'ignika',
  'akida',
  'tahtorak',
  'onu-koro',
  'kongu',
  'dikapi',
  'pakastaa',
  'nireta',
  'mahri',
  'kylma',
  'jagiri',
  'boxor',
  'jutlin',
  'felnas',
  'branar',
  'certavus',
  'ehlek',
  'arktinen',
  'takea',
  'comet',
  'kirbold',
  'gadunka',
  'calix',
  'klakk',
  'conjurer',
  'kiril',
  'wairuha',
  'daxia',
  'artakha',
  'acidfly',
  'mangai',
  'silence',
  'po-suva',
  'kolhii',
  'kabrua',
  'keystone',
  'turaga',
  'midak',
  'skirmix',
  'olmak',
  'kapura',
  'po-kini',
  'makani',
  'phantom',
  'kiina',
  'kraatu',
  'nui-jaga',
  'vulcanus',
  'amphibax',
  'ekimu',
  'amaja',
  'uniter',
  'tehutti',
  'kopaka',
  'gaaki',
  'baranus',
  'mimic',
  'fenrakk',
  'tuuli',
  'ga-koro',
  'rokreng',
  'crast',
  'takua',
  'spinax',
  'onu-kini',
  'za-kal',
  'ussal',
  'ko-kini',
  'tahnok',
  'dekar',
  'lhikan',
  'marendar',
  'x-glider',
  'hordika',
  'savage',
  'nixie',
  'zaktan',
  'aodhan',
  'gukko',
  'krahka',
  'ta-kini',
  'kardas',
  'pohatu',
  'bitil',
  'maxilos',
  'icarax',
  'ketar',
  'graalok',
  'okoth',
  'nynrah',
  'shasa',
  'gatherer',
  'terak',
  'gahdok',
  'komau',
  'miserix',
  'matoran',
  'ta-suva',
  'avohkii',
  'kuma-nui',
  'devourer',
  'ngalawa',
  'lesovikk',
  'umarak',
  'vatuka',
  'exsidian',
  'ta-metru',
  'jerbraz',
  'vezok',
  'hewkii',
  'ga-suva',
  'tamaru',
  'zamor',
  'spikit',
  'kadin',
  'garai',
  'vastus',
  'bionicle',
  'tuyet',
  'rahkshi',
  'narmoto',
  'ancient',
  'tarduk',
  'hagah',
  'motara',
  'hakann',
  'piatra',
  'likus',
  'umbra',
  'iconox',
  'agarak',
  'strakk',
  'piruk',
  'manas',
  'avohkah',
  'le-metru',
  'kalmah',
  'onewa',
  'muaka',
  'ta-wahi',
  'mamru',
  'sahmad',
  'onepu',
  'mangaia',
  'atero',
  'ga-kini',
  'pouks',
  'irnakk',
  'okotan',
  'solek',
  'sanok',
  'bordakh',
  'korgot',
  'telluris',
  'pewku',
  'suukorak',
  'ko-metru',
  'pokawi',
  'johmak',
  'taipu',
  'ahkmou',
  'rahaga',
  'gahlok',
  'kantai',
  'flammik',
  'agori',
  'fikou',
  'axonn',
  'morak',
  'ca-kal',
  'roxtus',
  'braca',
  'teridax',
  'phantoka',
  'coliseum',
  'keahi',
  'akaku',
  'toudo',
  'xa-kal',
  'baterra',
  'vorzakh',
  'melum',
  'seeker',
  'makika',
  'krana',
  'kivoda',
  'ga-wahi',
  'ehrye',
  'vu-kal',
  'rorzakh',
  'kurahk',
  'kavinika',
  'click',
  'berix',
  'onu-wahi',
  'zatth',
  'antroz',
  'raanu',
  'yo-kal',
  'faxon',
  'keelerak',
  'kopeke',
  'ko-koro',
  'ranama',
  'jaller',
  'vultraz',
  'destral',
  'axato',
  'krika',
  'kane-ra',
  'kodan',
  'dormus',
  'nokama',
  'zaria',
  'inika',
  'barraki',
  'damek',
  'devourer',
  'botar',
  'dezalk',
  'mavrah',
  'kini-nui',
  'kraawa',
  'krakua',
  'boggarak',
  'mantax',
  'bingzak',
  'kohrak',
  'gaardus',
  'lariska',
  'kulta',
  'chiara',
  'nobua',
  'makoki',
  'kaukau',
  'tohunga',
  'charger',
  'vortixx',
  'kanoka',
  'airweed',
  'velika',
  'fireroot',
  'vohtarak',
  'vhisola',
  'takanuva',
  'vican',
  'kualus',
  'ussanui',
  'su-kal',
  'nivawk',
  'niazesk',
  'waikiru',
  'onu-suva',
  'nikila',
  'pehkui',
  'visorak',
  'droton',
  'zemya',
  'golyo',
  'nilkuu',
  'defilak',
  'balta',
  'po-wahi',
  'mazeka',
  'lohrak',
  'hydruka',
  'troller',
  'triglax',
  'primal',
  'vamprah',
  'po-koro',
  'ko-wahi',
  'rockoh',
  'epena',
  'idris',
  'zakaz',
  'kollorak',
  'voporak',
  'po-metru',
  'tesara',
  'tajun',
  'sidorak',
  'vezon',
  'guurahk',
  'nui-rama',
  'orkan',
  'artidax',
  'panrahk',
  'cahdok',
  'tri-claw',
  'kikanalo',
  'longfang',
  'kirbraz',
  'amaya',
  'vohon',
  'nocturn',
  'trinuma',
  'taiki',
  'gadjati',
  'mohtrek',
  'manutri',
  'bohrok',
  'helryx',
  'chirox',
  'minion',
  'akamai',
  'skyboard',
  'matatu',
  'tarix',
  'akmuo',
  'metus',
  'volitak',
  'ko-suva',
  'marka',
  'odina',
  'zyglak',
  'tanma',
  'sanso',
  'hahli',
  'photok',
  'skakdi',
  'hikaki',
  'tobduk',
  'archives',
  'tarakava',
  'pridak',
  'tridax',
  'thornax',
  'keras',
  //////////////////////////////////////////////////////////////////////////////
  'akida',
  'epolim',
  'archives',
  'keelerak',
  'makika',
  'skirmix',
  'chiara',
  'cahdok',
  'reysa',
  'waikiru',
  'bitil',
  'savage',
  'odina',
  'kalmah',
  'pakari',
  'nobua',
  'krana',
  'tridax',
  'morak',
  'gadunka',
  'boxor',
  'rockoh',
  'le-metru',
  'vican',
  'kardas',
  'ko-suva',
  'amaya',
  'annona',
  'nixie',
  'gahlok',
  'defilak',
  'tuuli',
  'vahki',
  'kraatu',
  'lerahk',
  'umarak',
  'bordakh',
  'kalama',
  'hapaka',
  'nocturn',
  'fenrakk',
  'amphibax',
  'gafna',
  'skopio',
  'jovan',
  'kahgarak',
  'carapar',
  'jaller',
  'ehrye',
  'rahaga',
  'turahk',
  'burnak',
  'codrex',
  'brutaka',
  'mimic',
  'vezon',
  'roporak',
  'kikanalo',
  'ancient',
  'tunneler',
  'kraahu',
  'mantax',
  'melum',
  'sahmad',
  'taipu',
  'kailani',
  'vizuna',
  'po-wahi',
  'turaga',
  'kopaka',
  'vakama',
  'moto-hub',
  'bo-kal',
  'tracker',
  'makani',
  'varian',
  'karzahni',
  'acidfly',
  'akamu',
  'skrall',
  'nui-rama',
  'nilkuu',
  'tarix',
  'perditus',
  'kodan',
  'vulcanus',
  'trinuma',
  'kualsi',
  'faxon',
  'kiina',
  'damek',
  'mana-ko',
  'kinloka',
  'pelagia',
  'po-koro',
  'pokawi',
  'nireta',
  'okoto',
  'tesara',
  'vamprah',
  'akamai',
  'kohrak',
  'le-suva',
  'gaardus',
  'pohatu',
  'mamoru',
  'dezalk',
  'arthron',
  'orkan',
  'taiki',
  'takadox',
  'udapo',
  'agarak',
  'crast',
  'takara',
  'ketar',
  'braca',
  'longfang',
  'tamaru',
  'okoth',
  'nuhvok',
  'ta-wahi',
  'hewkii',
  'etoku',
  'boggarak',
  'johmak',
  'gorast',
  'skyboard',
  'spiriah',
  'krahka',
  'vorahk',
  'ga-suva',
  'kestora',
  'keystone',
  'piraka',
  'nikila',
  'teridax',
  'kaita',
  'ja-kal',
  'tohunga',
  'jutlin',
  'manutri',
  'kraawa',
  'kantai',
  'kojol',
  'charger',
  'kollorak',
  'mahiki',
  'matatu',
  'harakeke',
  'tarakava',
  'izotor',
  'kadin',
  'marendar',
  'jaatikko',
  'okotan',
  'su-kal',
  'rahkshi',
  'sentrakh',
  'gatherer',
  'kirbold',
  'tarduk',
  'brakas',
  'krakua',
  'niazesk',
  'felnas',
  'motara',
  'avohkah',
  'jetrax',
  'kini-nui',
  'maglya',
  'mistika',
  'kulta',
  'onewa',
  'harvali',
  'vohtarak',
  'komau',
  'ussal',
  'onepu',
  'kavinika',
  'keerakh',
  'ga-kini',
  'hordika',
  'nuparu',
  'gavla',
  'ko-wahi',
  'triglax',
  'suletu',
  'piruk',
  'strakk',
  'zadakh',
  'amaja',
  'vu-kal',
  'reidak',
  'onu-wahi',
  'nynrah',
  'sarda',
  'tanma',
  'droton',
  'metru',
  'marka',
  'icarax',
  'axonn',
  'solek',
  'olisi',
  'sanso',
  'dekar',
  'onu-suva',
  'kivoda',
  'silence',
  'agori',
  'tahnok',
  'takua',
  'dosne',
  'atakus',
  'xa-kal',
  'vezok',
  'gresh',
  'onu-koro',
  'minion',
  'matoro',
  'seeker',
  'visorak',
  'ahkmou',
  'kokkan',
  'bingzak',
  'ehlek',
  'cordak',
  'skakdi',
  'bionicle',
  'korgot',
  'klakk',
  'lehvak',
  'raanu',
  'baterra',
  'ko-koro',
  'valmai',
  'hagah',
  'guardian',
  'stelt',
  'bohrok',
  'roodaka',
  'panrahk',
  'spikit',
  'wairuha',
  'mangaia',
  'zaria',
  'za-kal',
  'pakastaa',
  'rorzakh',
  'po-metru',
  'zemya',
  'exo-toa',
  'antroz',
  'kuma-nui',
  'sanok',
  'macku',
  'aiyetoro',
  'mangai',
  'zivon',
  'zyglak',
  'devourer',
  'orkahm',
  'hahli',
  'stronius',
  'yo-kal',
  'vanisher',
  'makoki',
  'norik',
  'tri-claw',
  'exsidian',
  'makuta',
  'heremus',
  'kaxium',
  'nivawk',
  'thornax',
  'lesovikk',
  'owaki',
  'graalok',
  'rhotuka',
  'suukorak',
  'kranua',
  'artakha',
  'vatuka',
  'troller',
  'mutran',
  'pehkui',
  'thulox',
  'lariska',
  'metus',
  'tehutti',
  'iconox',
  'daxia',
  'botar',
  'protodax',
  'bomonga',
  'zatth',
  'vorzakh',
  'ranama',
  'cendox',
  'bahrag',
  'de-koro',
  'hydruka',
  'surel',
  'mazeka',
  'ko-kini',
  'brander',
  'keahi',
  'ackar',
  'azibo',
  'sanctum',
  'guurahk',
  'ga-metru',
  'uniter',
  'phantom',
  'calix',
  'vultraz',
  'kylma',
  'avohkii',
  'mahri',
  'ekimu',
  'jagiri',
  'baranus',
  'widget',
  'ba-koro',
  'malum',
  'kapura',
  'scarabax',
  'le-koro',
  'x-glider',
  'golyo',
  'ghekula',
  'midak',
  'iruini',
  'garai',
  'kiril',
  'shasa',
  'velika',
  'vohon',
  'ta-koro',
  'le-kini',
  'jerbraz',
  'barraki',
  'conjurer',
  'axalara',
  'destral',
  'irnakk',
  'branar',
  'nuurakh',
  'nokama',
  'click',
  'hydraxon',
  'garan',
  'kanohi',
  'mamuk',
  'axato',
  'takea',
  'hikaki',
  'kraata',
  'melea',
  'miserix',
  'spinax',
  'kongu',
  'mukau',
  'krekka',
  'chirox',
  'akilini',
  'manas',
  'umbra',
  'ga-wahi',
  'tajun',
  'narmoto',
  'muaka',
  'ngalawa',
  'gukko',
  'vorox',
  'pewku',
  'lhikan',
  'ignika',
  'lurker',
  'pahrak',
  'kanoka',
  'po-kini',
  'photok',
  'kerato',
  'kaukau',
  'ta-suva',
  'kamen',
  'tyrant',
  'uganu',
  'nui-jaga',
  'akaku',
  'talvi',
  'buzkayo',
  'daikau',
  'keetongu',
  'epena',
  'balta',
  'whenua',
  'airweed',
  'zamor',
  'lohrak',
  'hakann',
  'phantoka',
  'ravager',
  'shelek',
  'bumonda',
  'kurahk',
  'arktinen',
  'telluris',
  'kralhi',
  'ussalry',
  'mohtrek',
  'toudo',
  'mavrah',
  'le-wahi',
  'pridak',
  'kabrua',
  'vortixx',
  'sidorak',
  'kane-ra',
  'oohnorak',
  'coliseum',
  'tobduk',
  'idris',
  'nektann',
  'fireroot',
  'ta-kini',
  'zakaz',
  'artidax',
  'rokreng',
  'kirbraz',
  'gadjati',
  'kraahkan',
  'ca-kal',
  'piatra',
  'boreas',
  'maxilos',
  'gahdok',
  'volitak',
  'dweller',
  'matau',
  'keras',
  'po-suva',
  'zaktan',
  'ussanui',
  'hahnah',
  'terak',
  'mamru',
  'angonce',
  'takanuva',
  'ga-koro',
  'devourer',
  'kualus',
  'berix',
  'gaaki',
  'helryx',
  'ignalu',
  'spinner',
  'ta-metru',
  'kakama',
  'pekka',
  'comet',
  'matoran',
  'flammik',
  'nuhrii',
  'kyrehx',
  'vastus',
  'likus',
  'vhisola',
  'olmak',
  'tuyet',
  'aodhan',
  'kopeke',
  'primal',
  'pouks',
  'roxtus',
  'certavus',
  'krika',
  'fikou',
  'atero',
  'onu-kini',
  'voporak',
  'korusca',
  'tryna',
  'kolhii',
  'kirop',
  'inika',
  'nidhiki',
  'tahtorak',
  'ko-metru',
  'radiak',
  'akmuo',
  'dormus',
  'dikapi',
  //////////////////////////////////////////////////////////////////////////////
  'kualsi',
  'roporak',
  'damek',
  'nuhvok',
  'tri-claw',
  'nokama',
  'avohkah',
  'balta',
  'matoro',
  'pehkui',
  'pridak',
  'po-koro',
  'amphibax',
  'macku',
  'shasa',
  'seeker',
  'arktinen',
  'takea',
  'kraata',
  'hahli',
  'perditus',
  'exo-toa',
  'chirox',
  'gadunka',
  'kestora',
  'likus',
  'panrahk',
  'le-wahi',
  'flammik',
  'ravager',
  'phantoka',
  'triglax',
  'zatth',
  'vezon',
  'lurker',
  'olisi',
  'le-kini',
  'ignika',
  'atakus',
  'mahiki',
  'nynrah',
  'artidax',
  'rorzakh',
  'primal',
  'azibo',
  'hagah',
  'ackar',
  'nuhrii',
  'tanma',
  'midak',
  'garai',
  'kirop',
  'tracker',
  'hydraxon',
  'metus',
  'nuparu',
  'mavrah',
  'faxon',
  'mana-ko',
  'etoku',
  'ja-kal',
  'charger',
  'klakk',
  'tehutti',
  'ga-metru',
  'ko-koro',
  'lesovikk',
  'jetrax',
  'korusca',
  'kranua',
  'kane-ra',
  'tarduk',
  'rockoh',
  'akamu',
  'vultraz',
  'waikiru',
  'tuuli',
  'zyglak',
  'tryna',
  'xa-kal',
  'bomonga',
  'matau',
  'brander',
  'ko-kini',
  'jaatikko',
  'owaki',
  'protodax',
  'antroz',
  'ba-koro',
  'mamru',
  'brakas',
  'tahnok',
  'vohon',
  'trinuma',
  'dweller',
  'kyrehx',
  'ussanui',
  'suukorak',
  'kulta',
  'axalara',
  'vorox',
  'teridax',
  'matoran',
  'kohrak',
  'gukko',
  'artakha',
  'bohrok',
  'spinner',
  'karzahni',
  'ngalawa',
  'kaukau',
  'kalama',
  'pewku',
  'tahtorak',
  'radiak',
  'pelagia',
  'umbra',
  'sanok',
  'komau',
  'terak',
  'toudo',
  'vizuna',
  'longfang',
  'bitil',
  'avohkii',
  'keystone',
  'rokreng',
  'ekimu',
  'x-glider',
  'telluris',
  'shelek',
  'krika',
  'pahrak',
  'rahkshi',
  'kurahk',
  'fireroot',
  'devourer',
  'oohnorak',
  'odina',
  'nuurakh',
  'kokkan',
  'sanctum',
  'miserix',
  'ta-metru',
  'kakama',
  'onewa',
  'akmuo',
  'varian',
  'chiara',
  'piraka',
  'krekka',
  'le-metru',
  'tuyet',
  'kopaka',
  'guardian',
  'nocturn',
  'okoth',
  'kongu',
  'cendox',
  'amaya',
  'inika',
  'orkahm',
  'su-kal',
  'hikaki',
  'lohrak',
  'vohtarak',
  'garan',
  'nixie',
  'okotan',
  'aodhan',
  'orkan',
  'widget',
  'kraahkan',
  'dikapi',
  'po-kini',
  'iconox',
  'melea',
  'spinax',
  'piatra',
  'narmoto',
  'akida',
  'stronius',
  'malum',
  'lehvak',
  'zaktan',
  'atero',
  'nireta',
  'za-kal',
  'zamor',
  'mazeka',
  'mangai',
  'mistika',
  'epena',
  'baterra',
  'vastus',
  'nivawk',
  'takanuva',
  'tarakava',
  'golyo',
  'jaller',
  'carapar',
  'boxor',
  'ga-kini',
  'visorak',
  'takua',
  'kalmah',
  'bumonda',
  'nikila',
  'gafna',
  'troller',
  'kamen',
  'ga-wahi',
  'wairuha',
  'turahk',
  'sarda',
  'piruk',
  'mimic',
  'coliseum',
  'makika',
  'matatu',
  'defilak',
  'cordak',
  'po-suva',
  'thornax',
  'kylma',
  'ancient',
  'vamprah',
  'turaga',
  'mukau',
  'hapaka',
  'airweed',
  'muaka',
  'vhisola',
  'valmai',
  'devourer',
  'mutran',
  'agarak',
  'pokawi',
  'jerbraz',
  'bionicle',
  'niazesk',
  'kralhi',
  'mantax',
  'certavus',
  'volitak',
  'gavla',
  'idris',
  'heremus',
  'raanu',
  'pekka',
  'daxia',
  'arthron',
  'amaja',
  'lariska',
  'olmak',
  'angonce',
  'photok',
  'click',
  'kadin',
  'calix',
  'ussal',
  'ignalu',
  'surel',
  'velika',
  'vican',
  'boreas',
  'codrex',
  'zivon',
  'gatherer',
  'kapura',
  'ko-suva',
  'krakua',
  'keerakh',
  'gaardus',
  'mamuk',
  'bahrag',
  'marendar',
  'fikou',
  'norik',
  'talvi',
  'spikit',
  'barraki',
  'sidorak',
  'johmak',
  'roxtus',
  'ga-suva',
  'okoto',
  'ta-koro',
  'axato',
  'conjurer',
  'kirbold',
  'kivoda',
  'exsidian',
  'kualus',
  'reidak',
  'krana',
  'skrall',
  'ranama',
  'graalok',
  'morak',
  'braca',
  'hakann',
  'solek',
  'skopio',
  'po-metru',
  'nui-jaga',
  'hydruka',
  'mahri',
  'nilkuu',
  'akaku',
  'ta-kini',
  'takara',
  'tohunga',
  'ta-suva',
  'kanoka',
  'tesara',
  'savage',
  'kabrua',
  'pakastaa',
  'uniter',
  'mangaia',
  'onu-koro',
  'hewkii',
  'ehrye',
  'zakaz',
  'skakdi',
  'bordakh',
  'zaria',
  'voporak',
  'botar',
  'kanohi',
  'kraahu',
  'tobduk',
  'tajun',
  'ahkmou',
  'sentrakh',
  'vanisher',
  'motara',
  'roodaka',
  'kraatu',
  'lerahk',
  'dosne',
  'kopeke',
  'thulox',
  'keelerak',
  'archives',
  'berix',
  'nektann',
  'taiki',
  'mamoru',
  'pakari',
  'gadjati',
  'pohatu',
  'krahka',
  'marka',
  'takadox',
  'crast',
  'jovan',
  'comet',
  'de-koro',
  'onu-suva',
  'droton',
  'brutaka',
  'silence',
  'kahgarak',
  'buzkayo',
  'onu-wahi',
  'kollorak',
  'felnas',
  'kraawa',
  'stelt',
  'udapo',
  'gahlok',
  'baranus',
  'bo-kal',
  'gresh',
  'manutri',
  'harvali',
  'nui-rama',
  'kinloka',
  'fenrakk',
  'manas',
  'kodan',
  'yo-kal',
  'maglya',
  'kaita',
  'guurahk',
  'ko-metru',
  'kailani',
  'daikau',
  'zemya',
  'kini-nui',
  'ketar',
  'iruini',
  'acidfly',
  'vakama',
  'burnak',
  'gaaki',
  'annona',
  'akilini',
  'vortixx',
  'taipu',
  'kojol',
  'jutlin',
  'ehlek',
  'reysa',
  'hordika',
  'le-koro',
  'branar',
  'mohtrek',
  'agori',
  'gahdok',
  'tyrant',
  'icarax',
  'rhotuka',
  'zadakh',
  'sanso',
  'dekar',
  'keetongu',
  'spiriah',
  'izotor',
  'ussalry',
  'ga-koro',
  'kardas',
  'kiril',
  'boggarak',
  'dezalk',
  'uganu',
  'vatuka',
  'makani',
  'onepu',
  'metru',
  'makoki',
  'vahki',
  'onu-kini',
  'vorzakh',
  'melum',
  'sahmad',
  'harakeke',
  'minion',
  'jagiri',
  'skyboard',
  'tridax',
  'kirbraz',
  'vorahk',
  'tarix',
  'po-wahi',
  'kavinika',
  'tunneler',
  'kaxium',
  'gorast',
  'keras',
  'rahaga',
  'umarak',
  'strakk',
  'korgot',
  'irnakk',
  'ko-wahi',
  'nidhiki',
  'kantai',
  'phantom',
  'maxilos',
  'skirmix',
  'epolim',
  'axonn',
  'scarabax',
  'hahnah',
  'nobua',
  'tamaru',
  'moto-hub',
  'kuma-nui',
  'helryx',
  'kikanalo',
  'ca-kal',
  'kiina',
  'vezok',
  'bingzak',
  'suletu',
  'dormus',
  'lhikan',
  'kerato',
  'keahi',
  'le-suva',
  'makuta',
  'aiyetoro',
  'ta-wahi',
  'vulcanus',
  'kolhii',
  'vu-kal',
  'ghekula',
  'pouks',
  'akamai',
  'whenua',
  'cahdok',
  'destral',
  //////////////////////////////////////////////////////////////////////////////
  'balta',
  'hagah',
  'nocturn',
  'varian',
  'onu-koro',
  'bo-kal',
  'metus',
  'jetrax',
  'matau',
  'uniter',
  'melum',
  'perditus',
  'brander',
  'defilak',
  'ackar',
  'kraahu',
  'agarak',
  'ko-wahi',
  'triglax',
  'rorzakh',
  'telluris',
  'thornax',
  'vamprah',
  'roporak',
  'kulta',
  'gahdok',
  'karzahni',
  'avohkah',
  'le-metru',
  'radiak',
  'gorast',
  'vorox',
  'hydraxon',
  'po-wahi',
  'malum',
  'toudo',
  'dikapi',
  'tuyet',
  'vorahk',
  'raanu',
  'daxia',
  'dekar',
  'harvali',
  'maglya',
  'talvi',
  'po-kini',
  'icarax',
  'po-koro',
  'damek',
  'lohrak',
  'skrall',
  'ga-metru',
  'umarak',
  'tarakava',
  'azibo',
  'cendox',
  'krekka',
  'whenua',
  'tobduk',
  'le-suva',
  'ca-kal',
  'akamu',
  'skyboard',
  'marendar',
  'matoran',
  'kestora',
  'kopaka',
  'inika',
  'krana',
  'odina',
  'kalmah',
  'mangaia',
  'kualsi',
  'atero',
  'kuma-nui',
  'burnak',
  'zemya',
  'longfang',
  'bionicle',
  'harakeke',
  'savage',
  'fireroot',
  'taipu',
  'ignalu',
  'nixie',
  'pahrak',
  'tracker',
  'piatra',
  'turahk',
  'aiyetoro',
  'vahki',
  'vanisher',
  'onewa',
  'ussanui',
  'lariska',
  'hewkii',
  'sentrakh',
  'krahka',
  'click',
  'fikou',
  'zatth',
  'ussalry',
  'shelek',
  'mamuk',
  'surel',
  'chiara',
  'akaku',
  'makuta',
  'angonce',
  'nuurakh',
  'po-metru',
  'kardas',
  'nynrah',
  'gatherer',
  'tunneler',
  'etoku',
  'muaka',
  'nivawk',
  'morak',
  'felnas',
  'irnakk',
  'maxilos',
  'ngalawa',
  'kokkan',
  'dweller',
  'bordakh',
  'umbra',
  'kantai',
  'mimic',
  'narmoto',
  'rahaga',
  'volitak',
  'kailani',
  'onu-kini',
  'nuhvok',
  'mukau',
  'mamru',
  'mazeka',
  'spiriah',
  'troller',
  'conjurer',
  'photok',
  'acidfly',
  'kanoka',
  'stronius',
  'vizuna',
  'destral',
  'marka',
  'vezon',
  'okotan',
  'sidorak',
  'kalama',
  'mahri',
  'kinloka',
  'xa-kal',
  'vhisola',
  'cahdok',
  'heremus',
  'gavla',
  'kapura',
  'flammik',
  'sanok',
  'izotor',
  'takua',
  'keras',
  'lurker',
  'mavrah',
  'pehkui',
  'codrex',
  'ko-koro',
  'artidax',
  'gafna',
  'le-wahi',
  'panrahk',
  'vastus',
  'primal',
  'boxor',
  'kiina',
  'bahrag',
  'spinner',
  'akamai',
  'kurahk',
  'fenrakk',
  'matoro',
  'lehvak',
  'ketar',
  'gresh',
  'su-kal',
  'bitil',
  'vakama',
  'bohrok',
  'devourer',
  'orkahm',
  'piruk',
  'golyo',
  'calix',
  'ranama',
  'ko-metru',
  'kraahkan',
  'keerakh',
  'tarduk',
  'ahkmou',
  'kirbraz',
  'hahnah',
  'protodax',
  'mantax',
  'pakari',
  'pekka',
  'sarda',
  'trinuma',
  'pewku',
  'ga-wahi',
  'olisi',
  'chirox',
  'mohtrek',
  'ta-koro',
  'pelagia',
  'niazesk',
  'dosne',
  'takea',
  'manutri',
  'takara',
  'piraka',
  'akilini',
  'ga-suva',
  'ko-suva',
  'ussal',
  'udapo',
  'kranua',
  'guurahk',
  'kohrak',
  'wairuha',
  'olmak',
  'nokama',
  'vu-kal',
  'hapaka',
  'ga-koro',
  'zamor',
  'kolhii',
  'suukorak',
  'nidhiki',
  'ta-metru',
  'korusca',
  'yo-kal',
  'amaja',
  'gaaki',
  'korgot',
  'macku',
  'zivon',
  'airweed',
  'amaya',
  'kyrehx',
  'kopeke',
  'reidak',
  'ravager',
  'scarabax',
  'vortixx',
  'seeker',
  'pakastaa',
  'keetongu',
  'carapar',
  'hydruka',
  'akmuo',
  'kaukau',
  'kraata',
  'minion',
  'nilkuu',
  'vohon',
  'makika',
  'hakann',
  'atakus',
  'po-suva',
  'tyrant',
  'idris',
  'ta-suva',
  'amphibax',
  'ehrye',
  'nobua',
  'komau',
  'agori',
  'mistika',
  'kivoda',
  'daikau',
  'motara',
  'graalok',
  'tamaru',
  'devourer',
  'kahgarak',
  'stelt',
  'norik',
  'zadakh',
  'nui-jaga',
  'hikaki',
  'rockoh',
  'berix',
  'garan',
  'archives',
  'kadin',
  'gaardus',
  'x-glider',
  'ekimu',
  'kikanalo',
  'rahkshi',
  'ja-kal',
  'kollorak',
  'matatu',
  'de-koro',
  'keystone',
  'terak',
  'rokreng',
  'lesovikk',
  'metru',
  'zaria',
  'epena',
  'kraawa',
  'kiril',
  'tridax',
  'johmak',
  'ghekula',
  'nuparu',
  'vorzakh',
  'ko-kini',
  'ta-kini',
  'teridax',
  'iconox',
  'velika',
  'vican',
  'pridak',
  'certavus',
  'nuhrii',
  'kerato',
  'mangai',
  'arktinen',
  'tryna',
  'baterra',
  'okoto',
  'kavinika',
  'keahi',
  'helryx',
  'pouks',
  'skakdi',
  'zyglak',
  'gukko',
  'vohtarak',
  'jaller',
  'mamoru',
  'jovan',
  'lerahk',
  'kraatu',
  'orkan',
  'manas',
  'oohnorak',
  'le-kini',
  'bomonga',
  'crast',
  'gadunka',
  'nireta',
  'dormus',
  'roodaka',
  'makani',
  'ancient',
  'moto-hub',
  'tesara',
  'annona',
  'jerbraz',
  'taiki',
  'tanma',
  'akida',
  'kane-ra',
  'ba-koro',
  'krika',
  'kaita',
  'jutlin',
  'kirop',
  'visorak',
  'strakk',
  'widget',
  'buzkayo',
  'nui-rama',
  'nikila',
  'axato',
  'branar',
  'takanuva',
  'kakama',
  'vatuka',
  'coliseum',
  'gadjati',
  'boggarak',
  'miserix',
  'pokawi',
  'kamen',
  'charger',
  'makoki',
  'boreas',
  'mutran',
  'mahiki',
  'kongu',
  'silence',
  'hordika',
  'exsidian',
  'okoth',
  'melea',
  'likus',
  'bumonda',
  'artakha',
  'kirbold',
  'uganu',
  'krakua',
  'klakk',
  'tajun',
  'roxtus',
  'shasa',
  'sanso',
  'sanctum',
  'kini-nui',
  'brakas',
  'ignika',
  'owaki',
  'keelerak',
  'cordak',
  'brutaka',
  'phantom',
  'onu-wahi',
  'kanohi',
  'mana-ko',
  'comet',
  'lhikan',
  'faxon',
  'botar',
  'thulox',
  'epolim',
  'barraki',
  'valmai',
  'vezok',
  'guardian',
  'nektann',
  'kabrua',
  'kylma',
  'garai',
  'ehlek',
  'midak',
  'jaatikko',
  'voporak',
  'solek',
  'axonn',
  'tohunga',
  'ga-kini',
  'phantoka',
  'zaktan',
  'skopio',
  'takadox',
  'ta-wahi',
  'tahtorak',
  'suletu',
  'le-koro',
  'tahnok',
  'spikit',
  'kojol',
  'tuuli',
  'jagiri',
  'avohkii',
  'rhotuka',
  'tehutti',
  'skirmix',
  'braca',
  'zakaz',
  'waikiru',
  'droton',
  'aodhan',
  'vulcanus',
  'bingzak',
  'spinax',
  'sahmad',
  'kralhi',
  'vultraz',
  'exo-toa',
  'hahli',
  'arthron',
  'reysa',
  'za-kal',
  'turaga',
  'tarix',
  'pohatu',
  'antroz',
  'kualus',
  'onu-suva',
  'kodan',
  'tri-claw',
  'axalara',
  'baranus',
  'kaxium',
  'onepu',
  'gahlok',
  'iruini',
  'dezalk',
  //////////////////////////////////////////////////////////////////////////////
  'zaria',
  'reidak',
  'spikit',
  'piatra',
  'akmuo',
  'akamai',
  'skopio',
  'guardian',
  'jaller',
  'savage',
  'kalama',
  'thornax',
  'rhotuka',
  'vatuka',
  'kinloka',
  'mana-ko',
  'dezalk',
  'ancient',
  'jutlin',
  'vakama',
  'kraawa',
  'tunneler',
  'destral',
  'agori',
  'akaku',
  'axalara',
  'ga-suva',
  'dweller',
  'ga-wahi',
  'botar',
  'cahdok',
  'kojol',
  'midak',
  'pehkui',
  'sanctum',
  'xa-kal',
  'azibo',
  'codrex',
  'kirbold',
  'hewkii',
  'kane-ra',
  'tuyet',
  'bo-kal',
  'buzkayo',
  'ravager',
  'ussal',
  'gorast',
  'axonn',
  'dekar',
  'kamen',
  'aiyetoro',
  'mangaia',
  'mangai',
  'onu-wahi',
  'okoto',
  'terak',
  'jagiri',
  'roxtus',
  'sarda',
  'le-suva',
  'telluris',
  'kini-nui',
  'lhikan',
  'kralhi',
  'pewku',
  'krekka',
  'onepu',
  'turaga',
  'heremus',
  'nuparu',
  'klakk',
  'sahmad',
  'amaya',
  'artakha',
  'protodax',
  'le-wahi',
  'epolim',
  'boreas',
  'pelagia',
  'tracker',
  'makuta',
  'mamru',
  'niazesk',
  'nuurakh',
  'nixie',
  'bomonga',
  'seeker',
  'atero',
  'exo-toa',
  'marka',
  'makika',
  'zaktan',
  'radiak',
  'dosne',
  'ta-koro',
  'kurahk',
  'pridak',
  'ketar',
  'vortixx',
  'troller',
  'vorox',
  'primal',
  'bingzak',
  'spinner',
  'po-suva',
  'makani',
  'kadin',
  'karzahni',
  'sidorak',
  'kopeke',
  'rorzakh',
  'archives',
  'avohkii',
  'harakeke',
  'daikau',
  'zadakh',
  'wairuha',
  'tarix',
  'surel',
  'onewa',
  'phantoka',
  'piruk',
  'malum',
  'takea',
  'vohtarak',
  'tyrant',
  'metus',
  'ko-kini',
  'minion',
  'kyrehx',
  'skakdi',
  'po-koro',
  'keras',
  'izotor',
  'uganu',
  'gadunka',
  'trinuma',
  'vanisher',
  'nidhiki',
  'kaita',
  'kiril',
  'kirbraz',
  'vezok',
  'melum',
  'silence',
  'hydruka',
  'moto-hub',
  'tesara',
  'za-kal',
  'korgot',
  'flammik',
  'norik',
  'onu-koro',
  'melea',
  'mohtrek',
  'kivoda',
  'korusca',
  'ba-koro',
  'tajun',
  'click',
  'pokawi',
  'nui-jaga',
  'icarax',
  'gaardus',
  'kardas',
  'vezon',
  'tahnok',
  'kaukau',
  'lurker',
  'widget',
  'ta-metru',
  'guurahk',
  'matau',
  'acidfly',
  'brutaka',
  'keelerak',
  'ta-suva',
  'lehvak',
  'shelek',
  'fireroot',
  'tanma',
  'ehrye',
  'tri-claw',
  'visorak',
  'kakama',
  'jovan',
  'devourer',
  'ga-metru',
  'faxon',
  'kanohi',
  'talvi',
  'carapar',
  'airweed',
  'sanso',
  'tridax',
  'zivon',
  'po-kini',
  'valmai',
  'likus',
  'su-kal',
  'avohkah',
  'burnak',
  'matoran',
  'vastus',
  'charger',
  'matoro',
  'garan',
  'inika',
  'longfang',
  'ekimu',
  'takanuva',
  'dikapi',
  'stronius',
  'kongu',
  'nocturn',
  'maglya',
  'narmoto',
  'arktinen',
  'idris',
  'panrahk',
  'lohrak',
  'skyboard',
  'roporak',
  'bitil',
  'udapo',
  'tryna',
  'kraata',
  'gavla',
  'okotan',
  'tamaru',
  'ko-metru',
  'suletu',
  'manas',
  'ignika',
  'branar',
  'voporak',
  'nivawk',
  'turahk',
  'irnakk',
  'gadjati',
  'nobua',
  'barraki',
  'nireta',
  'zyglak',
  'vhisola',
  'oohnorak',
  'cordak',
  'ga-koro',
  'ignalu',
  'nuhrii',
  'nynrah',
  'vican',
  'brakas',
  'komau',
  'kapura',
  'phantom',
  'mutran',
  'waikiru',
  'skirmix',
  'atakus',
  'mavrah',
  'epena',
  'takadox',
  'spiriah',
  'gresh',
  'arthron',
  'vahki',
  'vohon',
  'vamprah',
  'kalmah',
  'olmak',
  'graalok',
  'berix',
  'de-koro',
  'lerahk',
  'matatu',
  'po-wahi',
  'kuma-nui',
  'orkahm',
  'bahrag',
  'kavinika',
  'brander',
  'ga-kini',
  'ahkmou',
  'lariska',
  'ja-kal',
  'akamu',
  'bumonda',
  'crast',
  'pouks',
  'tehutti',
  'mimic',
  'kraahu',
  'vu-kal',
  'gafna',
  'morak',
  'kualus',
  'onu-kini',
  'kailani',
  'strakk',
  'bohrok',
  'tarakava',
  'velika',
  'kulta',
  'dormus',
  'rahaga',
  'metru',
  'taiki',
  'gukko',
  'etoku',
  'certavus',
  'harvali',
  'damek',
  'taipu',
  'volitak',
  'vorzakh',
  'manutri',
  'nektann',
  'mazeka',
  'ko-suva',
  'mistika',
  'amphibax',
  'kranua',
  'kylma',
  'tuuli',
  'keerakh',
  'x-glider',
  'iconox',
  'miserix',
  'kodan',
  'umarak',
  'ca-kal',
  'daxia',
  'piraka',
  'photok',
  'rockoh',
  'spinax',
  'kohrak',
  'le-koro',
  'iruini',
  'kantai',
  'braca',
  'hahli',
  'bordakh',
  'hagah',
  'krana',
  'muaka',
  'johmak',
  'jaatikko',
  'skrall',
  'hapaka',
  'thulox',
  'yo-kal',
  'vulcanus',
  'tarduk',
  'mahri',
  'agarak',
  'mamuk',
  'nui-rama',
  'gatherer',
  'pekka',
  'fikou',
  'orkan',
  'ehlek',
  'zamor',
  'garai',
  'coliseum',
  'stelt',
  'pohatu',
  'tobduk',
  'makoki',
  'sanok',
  'pakastaa',
  'nilkuu',
  'shasa',
  'owaki',
  'lesovikk',
  'ranama',
  'chiara',
  'droton',
  'vultraz',
  'zemya',
  'zakaz',
  'vorahk',
  'balta',
  'hordika',
  'uniter',
  'cendox',
  'tohunga',
  'kaxium',
  'nuhvok',
  'mamoru',
  'calix',
  'ackar',
  'macku',
  'ko-wahi',
  'olisi',
  'kolhii',
  'kiina',
  'rahkshi',
  'solek',
  'onu-suva',
  'kopaka',
  'defilak',
  'le-metru',
  'golyo',
  'ko-koro',
  'triglax',
  'jetrax',
  'mahiki',
  'ussanui',
  'conjurer',
  'po-metru',
  'scarabax',
  'teridax',
  'gahlok',
  'rokreng',
  'motara',
  'keystone',
  'amaja',
  'raanu',
  'nikila',
  'pakari',
  'hakann',
  'whenua',
  'le-kini',
  'axato',
  'maxilos',
  'umbra',
  'bionicle',
  'kollorak',
  'kanoka',
  'aodhan',
  'suukorak',
  'akilini',
  'hahnah',
  'takara',
  'kahgarak',
  'chirox',
  'vizuna',
  'gaaki',
  'hikaki',
  'felnas',
  'devourer',
  'kraatu',
  'odina',
  'helryx',
  'ghekula',
  'hydraxon',
  'fenrakk',
  'angonce',
  'kabrua',
  'comet',
  'ta-wahi',
  'tahtorak',
  'kerato',
  'krahka',
  'reysa',
  'keetongu',
  'okoth',
  'gahdok',
  'mukau',
  'sentrakh',
  'kestora',
  'kualsi',
  'kikanalo',
  'perditus',
  'annona',
  'baterra',
  'ta-kini',
  'keahi',
  'krika',
  'artidax',
  'boxor',
  'kokkan',
  'ussalry',
  'jerbraz',
  'ngalawa',
  'antroz',
  'krakua',
  'exsidian',
  'roodaka',
  'baranus',
  'zatth',
  'pahrak',
  'akida',
  'boggarak',
  'nokama',
  'mantax',
  'kraahkan',
  'varian',
  'takua',
  'marendar',
  'toudo',
  'kirop',
  //////////////////////////////////////////////////////////////////////////////
  'keetongu',
  'hapaka',
  'boggarak',
  'harakeke',
  'wairuha',
  'ko-suva',
  'nui-rama',
  'tohunga',
  'jutlin',
  'komau',
  'kamen',
  'vorox',
  'baranus',
  'mangai',
  'onu-wahi',
  'perditus',
  'bo-kal',
  'seeker',
  'balta',
  'ravager',
  'kikanalo',
  'ussal',
  'kalmah',
  'ta-koro',
  'valmai',
  'hahnah',
  'sanctum',
  'maglya',
  'pokawi',
  'gadjati',
  'gahdok',
  'kiina',
  'ga-koro',
  'vamprah',
  'phantoka',
  'gaaki',
  'fikou',
  'vu-kal',
  'le-suva',
  'surel',
  'krika',
  'marendar',
  'sarda',
  'onu-kini',
  'x-glider',
  'archives',
  'korusca',
  'lurker',
  'ta-suva',
  'ussalry',
  'nynrah',
  'jerbraz',
  'kiril',
  'tanma',
  'burnak',
  'zivon',
  'mamuk',
  'keystone',
  'etoku',
  'akamai',
  'rokreng',
  'idris',
  'kranua',
  'arktinen',
  'kardas',
  'taipu',
  'pewku',
  'zakaz',
  'hewkii',
  'muaka',
  'spiriah',
  'umarak',
  'mohtrek',
  'vortixx',
  'krakua',
  'vultraz',
  'roodaka',
  'nuhrii',
  'karzahni',
  'takea',
  'talvi',
  'mazeka',
  'nobua',
  'krana',
  'klakk',
  'branar',
  'malum',
  'kraatu',
  'ghekula',
  'akaku',
  'sentrakh',
  'chiara',
  'dweller',
  'silence',
  'hydruka',
  'hydraxon',
  'vulcanus',
  'agarak',
  'shasa',
  'kualsi',
  'fenrakk',
  'za-kal',
  'mavrah',
  'icarax',
  'boreas',
  'kaukau',
  'macku',
  'takanuva',
  'po-kini',
  'nuhvok',
  'amaja',
  'triglax',
  'protodax',
  'lerahk',
  'ta-wahi',
  'ancient',
  'kodan',
  'ussanui',
  'mana-ko',
  'tracker',
  'ga-wahi',
  'piraka',
  'krekka',
  'bohrok',
  'rhotuka',
  'pelagia',
  'skopio',
  'kraahkan',
  'rockoh',
  'daikau',
  'rorzakh',
  'stelt',
  'acidfly',
  'izotor',
  'bumonda',
  'photok',
  'makoki',
  'ketar',
  'pridak',
  'epolim',
  'iruini',
  'onu-koro',
  'pakari',
  'nidhiki',
  'makuta',
  'tesara',
  'gahlok',
  'lesovikk',
  'skrall',
  'moto-hub',
  'terak',
  'vatuka',
  'bomonga',
  'daxia',
  'vican',
  'garai',
  'vezon',
  'ta-kini',
  'vohtarak',
  'ga-kini',
  'gaardus',
  'kahgarak',
  'kollorak',
  'exo-toa',
  'tuyet',
  'agori',
  'ignika',
  'miserix',
  'amphibax',
  'keerakh',
  'ko-koro',
  'matoran',
  'inika',
  'ekimu',
  'kabrua',
  'akilini',
  'onu-suva',
  'pohatu',
  'tryna',
  'devourer',
  'ko-metru',
  'manas',
  'braca',
  'epena',
  'xa-kal',
  'bahrag',
  'takara',
  'damek',
  'sidorak',
  'uniter',
  'panrahk',
  'aodhan',
  'ngalawa',
  'kanoka',
  'nui-jaga',
  'dosne',
  'kalama',
  'hikaki',
  'tamaru',
  'hakann',
  'ta-metru',
  'jaller',
  'turaga',
  'kaxium',
  'tarix',
  'odina',
  'tahtorak',
  'mahiki',
  'fireroot',
  'jagiri',
  'volitak',
  'melum',
  'makika',
  'flammik',
  'cendox',
  'hordika',
  'scarabax',
  'ko-kini',
  'nivawk',
  'hahli',
  'nilkuu',
  'ignalu',
  'chirox',
  'akida',
  'norik',
  'okoto',
  'kylma',
  'yo-kal',
  'jovan',
  'gadunka',
  'tarakava',
  'kailani',
  'akmuo',
  'bingzak',
  'keahi',
  'zaktan',
  'gatherer',
  'kantai',
  'barraki',
  'kraahu',
  'velika',
  'matoro',
  'rahaga',
  'zadakh',
  'savage',
  'kurahk',
  'tobduk',
  'krahka',
  'skakdi',
  'amaya',
  'mutran',
  'droton',
  'korgot',
  'radiak',
  'ca-kal',
  'exsidian',
  'olmak',
  'narmoto',
  'zemya',
  'zatth',
  'zyglak',
  'zaria',
  'ackar',
  'harvali',
  'sanok',
  'okotan',
  'helryx',
  'spikit',
  'sanso',
  'johmak',
  'arthron',
  'bitil',
  'calix',
  'kraata',
  'owaki',
  'okoth',
  'taiki',
  'po-wahi',
  'de-koro',
  'cordak',
  'kojol',
  'metru',
  'rahkshi',
  'dikapi',
  'nireta',
  'sahmad',
  'angonce',
  'roporak',
  'skyboard',
  'le-kini',
  'thornax',
  'shelek',
  'iconox',
  'melea',
  'reysa',
  'takadox',
  'garan',
  'kirbold',
  'pehkui',
  'buzkayo',
  'nuparu',
  'kakama',
  'nokama',
  'crast',
  'uganu',
  'voporak',
  'raanu',
  'suukorak',
  'kadin',
  'brander',
  'tridax',
  'kavinika',
  'motara',
  'tahnok',
  'tehutti',
  'keelerak',
  'nocturn',
  'airweed',
  'artidax',
  'tyrant',
  'longfang',
  'botar',
  'pekka',
  'brutaka',
  'golyo',
  'kongu',
  'solek',
  'onewa',
  'mamoru',
  'coliseum',
  'berix',
  'kolhii',
  'kirbraz',
  'suletu',
  'axalara',
  'irnakk',
  'click',
  'vakama',
  'axonn',
  'spinner',
  'heremus',
  'vanisher',
  'teridax',
  'lariska',
  'kohrak',
  'udapo',
  'vastus',
  'toudo',
  'lohrak',
  'certavus',
  'hagah',
  'phantom',
  'po-suva',
  'telluris',
  'ba-koro',
  'atero',
  'conjurer',
  'carapar',
  'nikila',
  'comet',
  'oohnorak',
  'kini-nui',
  'zamor',
  'widget',
  'skirmix',
  'mangaia',
  'bordakh',
  'maxilos',
  'jaatikko',
  'piruk',
  'nuurakh',
  'pouks',
  'ranama',
  'po-metru',
  'orkahm',
  'codrex',
  'avohkah',
  'aiyetoro',
  'kopaka',
  'morak',
  'spinax',
  'kirop',
  'dezalk',
  'mimic',
  'mahri',
  'stronius',
  'keras',
  'mistika',
  'lehvak',
  'gorast',
  'vohon',
  'ko-wahi',
  'umbra',
  'destral',
  'waikiru',
  'gavla',
  'kaita',
  'onepu',
  'kopeke',
  'ja-kal',
  'tarduk',
  'matatu',
  'boxor',
  'gukko',
  'tuuli',
  'primal',
  'varian',
  'devourer',
  'kestora',
  'makani',
  'kane-ra',
  'ga-metru',
  'takua',
  'antroz',
  'whenua',
  'le-metru',
  'pakastaa',
  'troller',
  'visorak',
  'defilak',
  'gresh',
  'kanohi',
  'ga-suva',
  'le-wahi',
  'tunneler',
  'turahk',
  'minion',
  'vhisola',
  'manutri',
  'le-koro',
  'kulta',
  'dekar',
  'piatra',
  'baterra',
  'marka',
  'kivoda',
  'kapura',
  'tajun',
  'kerato',
  'avohkii',
  'azibo',
  'akamu',
  'guurahk',
  'brakas',
  'kyrehx',
  'su-kal',
  'strakk',
  'annona',
  'nektann',
  'vizuna',
  'cahdok',
  'matau',
  'mantax',
  'kraawa',
  'likus',
  'bionicle',
  'artakha',
  'ehrye',
  'gafna',
  'vorahk',
  'midak',
  'dormus',
  'guardian',
  'charger',
  'kuma-nui',
  'felnas',
  'thulox',
  'kualus',
  'reidak',
  'faxon',
  'kokkan',
  'nixie',
  'graalok',
  'orkan',
  'roxtus',
  'trinuma',
  'mamru',
  'ahkmou',
  'vezok',
  'metus',
  'kralhi',
  'lhikan',
  'ehlek',
  'olisi',
  'vahki',
  'pahrak',
  'atakus',
  'po-koro',
  'axato',
  'jetrax',
  'kinloka',
  'niazesk',
  'vorzakh',
  'tri-claw',
  'mukau',
]
