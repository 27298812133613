import {
  HomeIcon,
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import {
  GAME_TITLE,
  HOW_TO_PLAY_TITLE,
  SETTINGS_TITLE,
  STATISTICS_TITLE,
} from '../../constants/strings'

type Props = {
  homeName: string
  homeUrl: string
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

export const Navbar = ({
  homeName,
  homeUrl,
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {
  return (
    <div className="navbar">
      <div className="navbar-content px-4">
        <div className="left-icons">
          <a title={homeName} href={homeUrl}>
            <HomeIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
          </a>
          <button
            title={HOW_TO_PLAY_TITLE}
            className="ml-3"
            onClick={() => setIsInfoModalOpen(true)}
          >
            <InformationCircleIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
          </button>
        </div>
        <h1 className="text-xl font-bold dark:text-white uses-matoran-font uses-matoran-font-title">
          {GAME_TITLE}
        </h1>
        <div className="right-icons">
          <button
            title={STATISTICS_TITLE}
            className="mr-3"
            onClick={() => setIsStatsModalOpen(true)}
          >
            <ChartBarIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
          </button>
          <button
            title={SETTINGS_TITLE}
            onClick={() => setIsSettingsModalOpen(true)}
          >
            <CogIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
          </button>
        </div>
      </div>
      <hr />
    </div>
  )
}
