import { MAX_CHALLENGES, HOME_NAME, HOME_URL } from '../../constants/settings'
import { HOW_TO_PLAY_TITLE } from '../../constants/strings'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title={HOW_TO_PLAY_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="text-sm text-stone-500 dark:text-stone-300">
        Guess the word in {MAX_CHALLENGES} tries. After each guess, the color of
        the tiles will change to show how close your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="T"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="K" />
        <Cell value="U" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-stone-500 dark:text-stone-300">
        The letter T is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Z" />
        <Cell value="A" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="K"
          status="present"
        />
        <Cell value="A" />
        <Cell value="Z" />
      </div>
      <p className="text-sm text-stone-500 dark:text-stone-300">
        The letter K is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" />
        <Cell value="O" />
        <Cell value="X" />
        <Cell isRevealing={true} isCompleted={true} value="O" status="absent" />
        <Cell value="R" />
      </div>
      <p className="text-sm text-stone-500 dark:text-stone-300">
        The letter O is not in the word in any spot.
      </p>

      <p className="mt-6 italic text-sm text-stone-500 dark:text-stone-300">
        By{' '}
        <a href={HOME_URL} className="underline font-bold">
          {HOME_NAME}
        </a>{' '}
        and open-source software.
      </p>
    </BaseModal>
  )
}
