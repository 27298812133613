export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = [
  '"So. You have surprised us again, Chronicler."',
  '"Not luck. It\'s what you do that makes you a hero."',
  '"All journeys must come to an end, but this time, there is a new beginning as well."',
]
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters'
export const WORD_NOT_FOUND_MESSAGE = 'Word not found'
export const HARD_MODE_NAME = 'Hard Mode'
export const HARD_MODE_ALERT_MESSAGE =
  'Hard Mode can only be enabled at the start!'
export const HARD_MODE_DESCRIPTION =
  'Any revealed hints must be used in subsequent guesses'
export const HIGH_CONTRAST_MODE_NAME = 'High Contrast Mode'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'For improved color vision'
export const DARK_MODE_NAME = 'Dark Mode'
export const DARK_MODE_DESCRIPTION = 'Enable the dark theme'
export const MATORAN_MODE_NAME = 'Matoran Mode'
export const MATORAN_MODE_DESCRIPTION = 'Use the Matoran font'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `The word was ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const HOW_TO_PLAY_TITLE = 'How to play'
export const STATISTICS_TITLE = 'Statistics'
export const SETTINGS_TITLE = 'Settings'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'New word in'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Total tries'
export const SUCCESS_RATE_TEXT = 'Success rate'
export const CURRENT_STREAK_TEXT = 'Current streak'
export const BEST_STREAK_TEXT = 'Best streak'
